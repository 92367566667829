<template>
  <div>
    <div class="whisper">
      <img class="logo" src="../assets/imgs/logo2.png" alt="" />
      <img class="text" src="../assets/imgs/whisperText2.png" alt="" />
    </div>
    <div class="order">
      <!-- <p class="title" v-if="lang !== 2">Payment Detail</p>
      <p class="title" v-else>تفاضيل الدفع</p> -->
      <p class="title">{{ payDetails[lang] }}</p>      

      <template v-if="good_id === 'good_19'">
        <ul>
          <li class="item">
            <div class="key" v-if="lang !== 2">{{ desc }}</div>
            <div class="key" v-else>الكوينزات</div>
            <div class="value">
              {{ `${queryData.first_month_day}-${queryData.last_month_day}` }}
            </div>
          </li>
        </ul>
      </template>
      <template v-else>
        <ul>
          <li class="item">
            <!-- <div class="key" v-if="lang !== 2">Coins</div>
            <div class="key" v-else>الكوينزات</div> -->
            <div class="key">{{ coinsText[lang] }}</div>
            <div class="value">{{ coins }}</div>
          </li>
        </ul>
      </template>
      <ul>
        <li class="item">
          <!-- <div class="key" v-if="lang !== 2">Amount</div>
          <div class="key" v-else>مبلغ الدفع</div> -->
          <div class="key">{{ amountText[lang] }}</div>
          <div class="value">{{ amount + " " + currency }}</div>
        </li>
      </ul>
      <ul>
        <li class="item">
          <!-- <div class="key" v-if="lang !== 2">Order Number</div>
          <div class="key" v-else>رقم الطلب</div> -->
          <div class="key">{{ ordernumberText[lang] }}</div>
          <div class="value">{{ code }}</div>
        </li>
      </ul>
      <!--谷歌支付  -->
      <div id="google-pay-button" class="google-pay" style="width: 100%"></div>
      <div class="unsupported">
        <!-- <div v-if="lang !== 2">Not available for your device</div>
        <div v-else>غير متاح للجهاز الخاص بك</div> -->
        <div>{{ devicenotAvailable[lang] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import https from "https";
import { baseRequest, cardPaymentMethod } from "@/assets/js/googlepay";
import {
  BASE_URL,
  GOOGLE_ENV,
  GOOGLE_MERCHANT_ID,
  GOOGLE_MERCHANT_NAME,
} from "@/assets/js/config";
import mixin2 from "../mixins/mixin2";

export default {
  name: "google-pay",
  mixins: [mixin2],
  data() {
    return {
      coins: this.$route.query.coins,
      amount: this.$route.query.amount,
      currency: this.$route.query.currency,
      code: this.$route.query.code,
      payment_id: this.$route.query.payment_id,
      pay_method: this.$route.query.pay_method,
      good_id: this.$route.query.good_id,
      desc: this.$route.query.desc,
        // cardPaymentMethod: null,
      queryData: this.$route.query,
      paymentsClient: null, 
    };
  },
  computed: {
    lang() {
      let lang = this.$route.query.lang;
      return lang ? parseInt(lang) : 1;
    },
    dir() {
      return this.lang !== 2 ? "ltr" : "rtl";
    },
    firstSymbols() {
      return this.queryData.first_month_day.replace(/\-/g, "/");
    },
    lastSymbols() {
      return this.queryData.last_month_day.replace(/\-/g, "/");
    },
  },
  created() {
    this.loadGooglePayApi();
  },
  methods: {
    getGoogleApiClient() {
      if (this.paymentsClient == null) {
        this.paymentsClient = new google.payments.api.PaymentsClient({
          environment: GOOGLE_ENV,
        });
      }
    },
    loadGooglePayApi() {
      console.log(this.$route.query, "路由参数");
      const googlePayScript = document.createElement("script");
      googlePayScript.setAttribute(
        "src",
        "https://pay.google.com/gp/p/js/pay.js"
      );
      googlePayScript.setAttribute("async", true);
      googlePayScript.setAttribute("defer", true);
      googlePayScript.onload = () => this.onGooglePayLoaded();
      document.head.appendChild(googlePayScript);
    },
    onGooglePayLoaded() {
      const isReadyToPayRequest = Object.assign({}, baseRequest);
      isReadyToPayRequest.allowedPaymentMethods = [cardPaymentMethod];
      this.getGoogleApiClient();
      this.paymentsClient
        .isReadyToPay(isReadyToPayRequest)
        .then((response) => {
          if (response.result) {
            // add a Google Pay payment button
            this.addGooglePayButton();
          } else {
            let unsupported = document.querySelector(".unsupported");
            unsupported.style.display = "block";
          }
        })
        .catch((err) => {
          let unsupported = document.querySelector(".unsupported");
          unsupported.style.display = "block";
          // show error in developer console for debugging
          console.error(err);
        });
    },
    addGooglePayButton() {
      const button = this.paymentsClient.createButton({
        onClick: () => this.googlePayButtonClick(),
        allowedPaymentMethods: [],
      }); // make sure to provide an allowed payment method
      document.getElementById("google-pay-button").appendChild(button);
    },
    googlePayButtonClick() {
      const paymentDataRequest = Object.assign({}, baseRequest);
      paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];

      paymentDataRequest.transactionInfo = {
        totalPriceStatus: "FINAL",
        totalPrice: this.amount, //金额
        currencyCode: this.currency, //汇率
        countryCode: "US",
      };
      // 配置
      paymentDataRequest.merchantInfo = {
        merchantName: GOOGLE_MERCHANT_NAME,
        merchantId: GOOGLE_MERCHANT_ID,
      };
      this.paymentsClient
        .loadPaymentData(paymentDataRequest)
        .then((paymentData) => {
          // if using gateway tokenization, pass this token without modification
          let paymentToken =
            paymentData.paymentMethodData.tokenizationData.token;
          let paymentTokenString = JSON.parse(paymentToken);
          console.log(paymentTokenString);
          this.payGoogle(paymentTokenString);
        })
        .catch((err) => {
          // show error in developer console for debugging
          console.error(err, "++++++++");
        });
    },
    payGoogle(paymentToken) {
      let url = BASE_URL + "/pay/checkout/payment/google/pay";
      let data = {
        payment_id: this.payment_id,
        token_data: paymentToken,
        lang: this.lang,
      };
      axios
        .post(url, data, {
          httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        })
        .then((res) => {
          // console.log(res)
          this.loading = false;
          if (res.status !== 200) {
            this.$router.push({ name: "fail", query: { lang: this.lang } });
          } else {
            if (res.data.code === 0) {
              res.data.data.lang = this.lang;
              this.$router.push({ name: "success", query: res.data.data });
            } else if (res.data.code === 202) {
              let redirect = res.data.data.redirect;
              window.location.href = redirect;
            } else {
              this.$router.push({ name: "fail", query: { lang: this.lang } });
            }
          }
        });
    },
  },
};
</script>

<style scoped lang='scss'>
.credit {
  text-align: left;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 121;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  vertical-align: middle;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-text {
  color: #ffffff;
}

.whisper {
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
}

.whisper .logo {
  width: 40px;
  height: 40px;
}

.whisper .text {
  width: auto;
  height: 40px;
}

.order {
  margin: 40px 30px 0;
}

.order .title {
  /*text-align: right;*/
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  border-bottom: 1px solid rgba(105, 120, 135, 0.3);
  height: 32px;
  font-size: 20px;
}

.order .item {
  height: 28px;
  display: flex;
  /*flex-direction: ;*/
  justify-content: space-between;
  width: 100%;
}

.item .key {
  /*text-align: right;*/
  font-weight: bold;
}

.item .value {
  margin-right: 0;
  text-align: right;
  font-size: 16px;
}

.payment {
  margin: 30px 30px 0;
  text-align: left;
}

.payment-title {
  /*text-align: right;*/
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  color: #6d6f73;
  border-bottom: 1px solid rgba(105, 120, 135, 0.3);
  height: 28px;
  font-size: 20px;
}

.payment-icons {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.payment-icon {
  width: 44px;
  height: 23px;
  border: 1px solid #697887;
  border-radius: 2px;
  margin: 2px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  padding: 1rem;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

iframe {
  /* This fixes a mobile Safari bug */
  height: 38px !important;
}

#payment-form {
  /*width: 280px;*/
  margin: 20px 30px 0;
}

label {
  display: block;
  height: 10px;
  color: #13395e;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  margin: 8px 0;
}

.date-and-code {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
}

.date-and-code > div:nth-child(1) {
  /*width: 55.715%;*/
  width: 54%;
}

.date-and-code > div:nth-child(2) {
  width: 45.719%;
}

.input-container {
  position: relative;
  display: flex;
  height: 40px;
}

.icon-container:last-child {
  right: 0;
}

.icon-container.payment-method {
  right: 0;
}

.input-container.card-number {
  margin-bottom: 8px;
}

.input-container.expiry-date {
  /*margin-right: 4px;*/
  /*margin-left: 4px;*/
}

.input-container.cvv {
  /*margin-left: 4px;*/
  /*margin-right: 4px;*/
}

.card-number-frame {
  padding-left: 40px;
  width: 100%;
}

.cardholder-frame {
  padding-left: 40px;
  width: 100%;
}

.cardholder-input {
  padding: 0;
  margin: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  /*font: 400 13.3333px Arial;*/
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  color: #13395e;
  -webkit-text-fill-color: #13395e;
  width: 100%;
  letter-spacing: -0.4px;
  /*font-family: monospace;*/
}

.expiry-date-frame {
  padding-left: 40px;
}

.cvv-frame {
  padding-left: 40px;
}

div + button {
  margin-top: 8px;
}

.icon-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 26px;
  margin: 0 7px;
}

.icon-container.payment-method {
  transform: translateY(-50%) rotateY(90deg);
  transition: opacity 0.15s ease-out;
  opacity: 0;
  top: 50%;
}

.icon-container.payment-method.show {
  opacity: 1;
  transition: all 0.4s ease-out;
  transform: translateY(-50%) rotateY(0deg);
}

.icon-container.payment-method img {
  width: 100%;
}

[id$="-error"] {
  display: none;
}

.frame {
  opacity: 0;
}

.frame--activated {
  opacity: 1;
  border: solid 1px #13395e;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
}

.frame--activated.frame--focus {
  border: solid 1px #13395e;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
}

.frame--activated.frame--invalid {
  border: solid 1px #d96830;
  box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
}

.error-message {
  display: block;
  color: #c9501c;
  font-size: 0.9rem;
  margin: 8px 0 0 1px;
  font-weight: 300;
}

#pay-button {
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  height: 40px;
  width: 100%;
  background-color: #13395e;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:active {
  background-color: #0b2a49;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:hover {
  background-color: #15406b;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:disabled {
  background-color: #697887;
  box-shadow: none;
}

#pay-button:not(:disabled) {
  cursor: pointer;
}

.success-payment-message {
  color: #13395e;
  line-height: 1.4;
}

.token {
  color: #b35e14;
  font-size: 0.9rem;
  font-family: monospace;
}

/**
        IE11-targeted optimisations
         */
_:-ms-fullscreen,
:root .icon-container {
  display: block;
}

_:-ms-fullscreen,
:root .icon-container img {
  top: 50%;
  -ms-transform: translateY(-50%);
  position: absolute;
}

_:-ms-fullscreen,
#icon-card-number,
_:-ms-fullscreen,
#icon-expiry-date,
_:-ms-fullscreen,
#icon-cvv {
  left: 7px;
}

#checkout-frames-card-number::-ms-clear {
  display: none;
}

#google-pay-button ::v-deep {
  margin-top: 20px;

  & .gpay-card-info-container.black,
  .gpay-card-info-animation-container.black {
    width: 100%;
  }
}

.unsupported {
  margin: 20px 30px;
  // display: none;
  color: #ee0a2e;
}
</style>
