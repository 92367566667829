<template>
  <div class="nobleLv3_er">
    <div class="nobleImg">
      <img src="../../assets/imgs/noble/lv3.png" alt="">
      <!-- <span>امتيازات 12/20</span> -->
      <span>{{ hasAuthArr.length }}/{{ list.length }} امتيازات</span>
    </div>
    <div class="displayFun">
      <ul>
        <li v-for="(item,index) in list" :key="index">
          <span v-if="index<=11" @click="a(index)">
            <img :src="item.img" alt="">
            <span class="helpful">{{item.name}}</span>
          </span>
          <span v-else>
            <img :src="item.img" alt="">
            <span>{{item.name}}</span>
          </span>
        </li>
      </ul>
    </div>
    <van-popup v-model="show">
      <div class="lunbo">
        <div class="dis">
          <van-swipe class="my-swipe" indicator-color="white" :initial-swipe="lunboIndex">
            <template v-for="(item,index) in newList">
              <van-swipe-item :key="index" v-if="item.gift!==undefined">
                <div class="content">
                  <span v-if="item.showBg===true" class="showBg">
                    <img :src="item.gift" alt="">
                  </span>
                  <span class="noBg" v-else> <img :src="item.gift" alt=""></span>
                  <span class="name" v-if="item.title!='' && item.title!=null && item.title!=undefined">{{item.title}}</span>
                  <span class="name" v-else>{{item.name}}</span>
                  <span class="text">{{item.content}}</span>
                </div>
              </van-swipe-item>
            </template>
          </van-swipe>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import SVGA from 'svgaplayerweb';
export default {
  name: 'nobleLv3_er',
  data () {
    return {
      list: [
        {
          img: require('../../assets/imgs/noble/g1.png'),
          name: 'شعار وزير',
          gift: require('../../assets/imgs/noble/lv3/lv3_gift1.png'),
          showBg: true,
          content: 'سيتم عرضها في: بطاقة المعلومات، الرسائل في الغرفة، قائمة الأصدقاء، طلبات الصداقة، صفحة الترتيب... '
        },
        {
          img: require('../../assets/imgs/noble/g2.png'),
          name: 'إطار المايك',
          gift: require('../../assets/imgs/noble/lv3/lv3_gift2.png'),
          showBg: true,
          content: 'يمكنك إستخدام هذا الإطار. يمكنك تعديل ذلك في "خزنة الزينة"'
        },
        {
          img: require('../../assets/imgs/noble/g3.png'),
          name: 'الترتيب الأعلى',
          gift: require('../../assets/imgs/noble/g3.png'),
          showBg: false,
          content: 'الترتيب الأعلى في قائمة المستخدمين في الغرفة'
        },
        {
          img: require('../../assets/imgs/noble/g4.png'),
          name: 'مكافأة مالك الغرفة',
          gift: require('../../assets/imgs/noble/g4.png'),
          showBg: false,
          content: 'يمكن لمالك الغرفة الحصول على %3.5 نسبة من الهدايا المرسلة داخل الغرفة'
        },
        {
          img: require('../../assets/imgs/noble/g5.png'),
          name: 'الدخول بالمؤثرات',
          showBg: true,
          gift: require('../../assets/imgs/noble/lv3/lv3_gift3.png'),
          content: 'يمكنك إظهار تأثيرات الدخول عند الدخول لأي غرفة'
        },
        {
          img: require('../../assets/imgs/noble/g6.png'),
          name: 'قفل الغرفة',
          showBg: false,
          gift: require('../../assets/imgs/noble/g6.png'),
          content: 'أقفل غرفتك، يمكن للآخرين الدخول فقط إذا كان لديهم الرقم السري للغرفة'
        },
        {
          img: require('../../assets/imgs/noble/g17.png'),
          name: 'غرفة البث المباشر',
          showBg: false,
          gift: require('../../assets/imgs/noble/g17.png'),
          content: 'فتح غرفة البث المباشر'
        },
        {
          img: require('../../assets/imgs/noble/g7.png'),
          name: 'ترقية عالية السرعة',
          showBg: false,
          gift: require('../../assets/imgs/noble/g7.png'),
          content: '\u202B 1.2 \u202B مرة زيادة في نقاط الخبرة'
        },
        {
          img: require('../../assets/imgs/noble/g8.png'),
          name: 'الرسائل الملونة',
          showBg: true,
          gift: require('../../assets/imgs/noble/lv3/lv3_gift4.png'),
          content: 'الرسائل التي أرسلت ستكون ملونة'
        },        
        {
          img: require('../../assets/imgs/noble/g21.png'),
          name: 'صور GIF',
          showBg: true,
          gift: require('../../assets/imgs/noble/g21.png'),
          content: 'تحميل صور GIF للصورة الشخصية، موضوع الصفحة الشخصية، موضوع الغرفة و صورة الهاشتاق...'
        },
        {
          img: require('../../assets/imgs/noble/g16.png'),
          name: "غرف الأصدقاء",
          showBg: false,
          gift: require('../../assets/imgs/noble/g16.png'),
          content: 'يمكنك إيجاد غرف أصدقائك بسهولة في صفحة "متعلق-أصدقاء" بسهولة'
        },
        {
          img: require('../../assets/imgs/noble/g9.png'),
          name: 'المركبات',
          showBg: true,
          gift: require('../../assets/imgs/noble/lv3/lv3_gift5.png'),
          content: 'يمكنك إستخدام هذا الإطار. يمكنك تعديل ذلك في "خزنة الزينة"'
        },
        {
          img: require('../../assets/imgs/noble/g10.png'),
          name: 'إخفاء الدولة',
          showBg: false,
          gift: require('../../assets/imgs/noble/g10.png'),
          content: 'سيتم إظهار دولتك كغير معروفة في صفحتك الشخصية'
        },
        {
          img: require('../../assets/imgs/noble/g15_2.png'),
          name: 'تسجيل دخول الأرستقراطية',
        },
        {
          img: require('../../assets/imgs/noble/g11_2.png'),
          name: 'هدايا حصرية',
        },
        {
          img: require('../../assets/imgs/noble/g12_2.png'),
          name: 'تحميل الموضوعات',
        },
        {
          img: require('../../assets/imgs/noble/g13_2.png'),
          name: 'مضاد الطرد',
        },
        {
          img: require('../../assets/imgs/noble/g14_2.png'),
          name: 'مضاد لحظر الكتابة',
        },
        {
          img: require('../../assets/imgs/noble/g18_2.png'),
          name: 'مايكات مميزة',

        },
        {
          img: require('../../assets/imgs/noble/g19_2.png'),
          name: 'إسم مستخدم مميز',
        },
        {
          img: require('../../assets/imgs/noble/g20_2.png'),
          name: 'إخفاء الغرفة',
        }

      ],
      show: false,
      lunboIndex: 0,
      newList: [],
      hasAuthArr: []
    }
  },
  created () {
    this.zlData()
    this.list.forEach(val => {if(val.gift && val.gift !== undefined) this.hasAuthArr.push(val)});  
  },
  methods: {
    a (index) {      
      console.log('当前点击原数组索引=====', index)
      this.lunboIndex = index
      // let num = this.hasAuthArr.length
      this.show = true
      // this.lunboIndex = Number(num - index)
      // console.log('lunboIndex==========', this.lunboIndex)
    },
    zlData () {
      let list = this.list
      let _newList = []
      for (let i = 0; i < list.length; i++) {
        if (list[i].gift !== undefined) {
          _newList.push(list[i])
        }
      }
      console.log('newList================', JSON.parse(JSON.stringify(_newList)))
      // _newList.reverse()
      this.newList = _newList
    }
  }
}
</script>

<style lang="scss">
.nobleLv3_er {
  #svgaBox {
    width: 20vw;
    height: 20vw;
  }
  .nobleImg {
    color: #333;
    font-size: 14px;
    margin-top: 5vw;
    span {
      display: block;
      font-weight: bold;
    }
    img {
      width: 40vw;
    }
  }
  .displayFun {
    padding-bottom: 25vw;
    ul {
      display: flex;
      flex-wrap: wrap;
      width: 90vw;
      margin: auto;
      margin-top: 4vw;
      li {
        width: 30vw;
        padding: 2vw 0;
        img {
          width: 8vw;
        }
        span {
          color: #959aa6;
          font-size: 12px;
          display: block;
          width: 20vw;
          margin: auto;
          margin-top: 1vw;
        }
        .helpful {
          color: #b08b55;
        }
      }
    }
  }
  .van-popup {
    direction: ltr;
    background: none;
  }
  .van-popup--center {
    height: 80vw;
  }
  .lunbo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .dis {
      background: #fff;
      width: 80vw;
      border-radius: 4vw;
      position: relative;
    }

    .van-swipe {
      position: initial;
    }

    .my-swipe .van-swipe-item {
      color: #333;
      text-align: center;
      border-radius: 4vw;
    }
    .van-swipe__indicators {
      bottom: -5vw;
    }
    .van-swipe__indicator {
      width: 5px;
      height: 5px;
    }
    .van-swipe__indicator--active {
      width: 13px;
      border-radius: 5px;
    }
    .content {
      .showBg {
        width: 65vw;
        background: url("../../assets/imgs/noble/gift_bg.png") no-repeat;
        background-size: 100%;
        margin: auto;
        margin-top: 7vw;
        img {
          height: 16vw;
          padding: 1vw 0;
        }
      }
      .noBg {
        img {
          height: 18vw;
          margin-top: 8vw;
        }
      }
      span {
        display: block;
      }
      .name {
        color: #333333;
        font-size: 18px;
        padding: 4vw;
        font-weight: bold;
      }
      .text {
        color: #6d6f73;
        font-size: 15px;
        width: 70vw;
        margin: auto;
        padding-bottom: 5vw;
        direction: rtl;
      }
    }
  }
}
</style>