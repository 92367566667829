<template>
  <div class="apple-pay" :dir="dir">
    <div class="whisper">
      <img class="logo" src="../assets/imgs/logo2.png" alt="" />
      <img class="text" src="../assets/imgs/whisperText2.png" alt="" />
    </div>
    <div class="order">    
      <p class="title">{{ payDetails[lang] }}</p>  
      <!-- 购买通行证 -->
      <template v-if="good_id === 'good_19'">
        <ul>
          <li class="item">
            <div class="key" v-if="lang !== 2">{{ desc }}</div>
            <div class="key" v-else>{{ $t('m.pass') }}</div>
            <!-- <div class="key" v-else>الكوينزات</div> -->
            <div class="value">
              {{ `${queryData.first_month_day}-${queryData.last_month_day}` }}
            </div>
          </li>
        </ul>
      </template>
      <template v-else>
        <!-- 购买的产品信息 rwchargeType: 1(金币) 2(礼包) -->
        <ul>
          <li class="item">
            <div class="key buy_type">{{ rwchargeType !== 1 ? coinsText[lang] : buygiftpackNames[lang] }} </div>
            <div class="value" v-if="rwchargeType === 0">{{ coins }}</div>
            <div class="value" v-else-if="rwchargeType=== 1 && Number(valid_day)<=1">{{ $t('m.giftandday', {m: offername + " " + currency, n: queryData.valid_day}) }}</div>
            <div class="value" v-else-if="rwchargeType=== 1 && Number(valid_day)>1">{{ $t('m.giftandday1', {m: offername + " " + currency, n: queryData.valid_day}) }}</div> 
            <div class="value" v-else>{{ coins }}</div>
          </li>
        </ul>
      </template>
      <!-- Amount -->
      <ul>
        <li class="item Amount">        
          <div class="key">{{ amountText[lang] }}</div>
          <div class="value">{{ amount + " " + currency }}</div>
        </li>
      </ul>
      <!-- Order Number -->
      <ul>
        <li class="item order_number">        
          <div class="key">{{ ordernumberText[lang] }}</div>
          <div class="value">{{ code }}</div>
        </li>
      </ul>
    </div>
    <!-- <apple-pay-button id="apple-pay-button" class="apple-pay-button" buttonstyle="black" type="plain" locale="en"></apple-pay-button> -->
    <div id="apple-pay-button" class="apple-pay-button"></div>
    <div class="unsupported">    
      <div>{{ devicenotAvailable[lang] }}</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import https from "https";
import { BASE_URL, APPLE_MERCHANT_ID } from "@/assets/js/config";
import mixin2 from "../mixins/mixin2";

export default {
  name: "apple-pay",  
  mixins: [mixin2],
  data() {
    return {
      queryData: this.$route.query,      
      offername: this.$route.query.objective ? this.$route.query.objective : "",   //礼包名称
      valid_day: this.$route.query.valid_day ? this.$route.query.valid_day || this.$route.query.data.valid_day : "",  //礼包有效期
      coins: this.$route.query.coins,  //金币价值
      amount: this.$route.query.amount || this.$route.query.data.amount,   //充值数量
      currency: this.$route.query.currency || this.$route.query.data.currency,  //货币类型
      code: this.$route.query.code === 0 ? this.$route.query.data.code : this.$route.query.code,  //订单号
      payment_id: this.$route.query.payment_id ? this.$route.query.payment_id : this.$route.query.data.payment_id,  //支付id
      pay_method: this.$route.query.pay_method,  //支付方式
      good_id: this.$route.query.good_id,
      applePaySession: null,
      desc: this.$route.query.desc || "" ,
      pay_type: this.$route.rwchargeType,
      offer_id: this.$route.offer_id,
      rwchargeType: Number(this.$route.query.rwchargeType) || 0, //礼包的充值类型 1：礼包充值  2：礼包下金币充值 (0-原先金币和通行证 1-礼包购买 2-礼包内充值)
      payDetails: { 
          1: 'Payment Detail', 
          2: 'تفاضيل الدفع',
          3: 'Ödeme detayları'
      },
    };
  },
  computed: {
    lang() {
      let lang = this.$route.query.lang;
      return lang ? parseInt(lang) : 1;
    },
    dir() {
      return this.lang !== 2 ? "ltr" : "rtl";
    },
  },
  created() {
    console.log('applePay页面 =================================')
    console.log(this.rwchargeType, '...............rwchargeType')
    console.log('路由参数.................',this.$route)
    this.loadApplePayApi();
    this.seti18nLang()
  },
  methods: {
    /**
     * @param 设置i18n语言
     * zh-EN  zh-ER  zh-TR
    */
    seti18nLang() {
        let routeobj = {
            1: {
                path: '/',
                langType: 'zh-EN'
            },
            2: {
                path: '/ar',
                langType: 'zh-ER'
            },
            3: {
                path: '/tr',
                langType: 'zh-TR'
            }
        }
        this.$i18n.locale = routeobj[this.lang].langType
        sessionStorage.setItem('lang', this.lang)
    },
    loadApplePayApi() {
      const applePayScript = document.createElement("script");
      applePayScript.setAttribute(
        "src",
        "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js"
      );
      applePayScript.setAttribute("async", true);
      applePayScript.setAttribute("defer", true);
      applePayScript.onload = () => this.onApplePayLoaded();
      document.head.appendChild(applePayScript);
    },
    onApplePayLoaded() {
      if (window.ApplePaySession) {
        ApplePaySession.canMakePaymentsWithActiveCard(APPLE_MERCHANT_ID)
          .then(() => {
            let appleButton = document.querySelector("#apple-pay-button");
            appleButton.style.display = "block";
            appleButton.onclick = () => this.onApplePayButtonClick();
          })
          .catch((err) => {
            ////console.log(err);
          });
      } else {
        let unsupported = document.querySelector(".unsupported");
        unsupported.style.display = "block";
      }
      // if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
      //     appleButton.style.display = "block";
      //     appleButton.onclick = () => this.onApplePayButtonClick()
      // }
    },
    onApplePayButtonClick() {
      this.applePaySession = new ApplePaySession(6, {
        countryCode: "US",
        currencyCode: this.currency,
        supportedNetworks: ["visa", "masterCard", "amex", "discover"],
        merchantCapabilities: ["supports3DS"],
        total: { label: "Whisper", amount: this.amount },
      });
      this.applePaySession.begin();
      this.applePaySession.onvalidatemerchant = (event) => {
        let validateUrl = event.validationURL;
        this.validateTheSession(validateUrl);
      };
      this.applePaySession.oncancel = (event) => {
        ////console.log(event);
      };
      this.applePaySession.onpaymentauthorized = (event) => {
        let applePayToken = event.payment.token;
        ////console.log(applePayToken);
        this.payApple(applePayToken.paymentData);
      };
    },
    validateTheSession(validateUrl) {
      let url = BASE_URL + "/pay/checkout/payment/apple/session";
      let data = {
        endpoint_url: validateUrl,
      };
      axios
        .post(url, data, {
          httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        })
        .then((res) => {
          // ////console.log(res)
          this.loading = false;
          if (res.status !== 200) {
            this.$router.push({ name: "fail" });
          } else {
            if (res.data.code === 0) {
              let merchantSession = res.data.data.merchantSession;
              this.applePaySession.completeMerchantValidation(merchantSession);
            } else {
              this.$router.push({ name: "fail" });
            }
          }
        })
        .catch((err) => {
          ////console.log(err);
        });
    },
    //pay_type 0-原先金币和通行证 1-礼包购买 2-礼包内充值
    payApple(applePayToken) {
      let url = BASE_URL + "/pay/checkout/payment/apple/pay";
      let data = {
          payment_id: this.payment_id,
          token_data: applePayToken,
          lang: this.lang,
          pay_type: this.pay_type        
      };
      // if(this.pay_type === 0) {
      //   data = {
      //     payment_id: this.payment_id,
      //     token_data: applePayToken,
      //     lang: this.lang,
      //     pay_type: this.pay_type        
      //   };
      // } else {
      //   data = {
      //     payment_id: this.payment_id,
      //     token_data: applePayToken,
      //     lang: this.lang,
      //     pay_type: this.pay_type,
      //     offer_id: this.offer_id,
      //     good_id: this.good_id        
      //   }
      // }
      // if(this.pay_type === 1) {
      //   delete data.good_id
      // }
      axios
        .post(url, data, {
          httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        })
        .then((res) => {
          // console.log(res, '..................苹果支付结果')
          this.loading = false;
          if (res.status !== 200) {
            this.applePaySession.completePayment(
              ApplePaySession.STATUS_FAILURE
            );
            this.$router.push({ name: "fail", query: { lang: this.lang } });
          } else {
            if (res.data.code === 0) {
              res.data.data.lang = this.lang;
              console.log(res.data.data, '..................苹果支付跳转成功页面')
              this.$router.push({ name: "success", query: res.data.data });
              this.applePaySession.completePayment(
                ApplePaySession.STATUS_SUCCESS
              );
            } else if (res.data.code === 202) {
              let redirect = res.data.data.redirect;
              console.log(redirect, '.........................苹果支付redirect回调链接')
              window.location.href = redirect;
            } else {
              console.log(res.data.data, '..................苹果支付支付失败跳转失败页面')
              this.applePaySession.completePayment(
                ApplePaySession.STATUS_FAILURE
              );
              this.$router.push({ name: "fail", query: { lang: this.lang } });
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.apple-pay-button {
  margin: 20px 30px;
  height: 40px;
  -webkit-appearance: -apple-pay-button;
  display: none;
}
.whisper {
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
}

.whisper .logo {
  width: 40px;
  height: 40px;
}

.whisper .text {
  width: auto;
  height: 40px;
}

.order {
  margin: 40px 30px 0;
}

.order .title {
  /*text-align: right;*/
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  border-bottom: 1px solid rgba(105, 120, 135, 0.3);
  height: 32px;
  font-size: 20px;
}

.order .item {
  height: 28px;
  display: flex;
  /*flex-direction: ;*/
  justify-content: space-between;
  width: 100%;
}

.item .key {
  /*text-align: right;*/
  font-weight: bold;
}

.item .value {
  margin-right: 0;
  text-align: right;
  font-size: 16px;
}

.unsupported {
  margin: 20px 30px;
  display: none;
  color: #ee0a2e;
}
.back {
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  padding: 1rem;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

iframe {
  /* This fixes a mobile Safari bug */
  height: 38px !important;
}
</style>