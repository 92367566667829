<template>
    <div class="commonProblem" dir="rtl">
    <span>
      <b>1 أين أتحقق من كوينزاتي بعد الشحن؟</b><br>
      <p>بعد الشحن، يمكنك فتح تطبيق ويسبر، والتحقق من كوينزاتك في صفحة "أنا" - "شحن الكوينزات"</p>
    </span>
        <span>
      <b>2 ماذا إذا لم أحصل على الكوينزات بعد إنتهاء عملية الشحن؟</b><br>
      <p>يمكنك التواصل معنا عبر الواتساب: +201032127473، وتقديم المعلومات التالية: 1 كمية الشحن 2 تاريخ ووقت الشحن 3 صورة لفاتورة الشحن. وسوف نقوم بتأكيد الطلب وإرسال الكوينزات لك بعد ذلك</p>
    </span>
        <span>
      <b>3 ماذا إذا شحنت ل ID خاطئ؟</b><br>
      <p>المعذرة، لا يمكنك استرجاع ملبغ الشحن بعد أي عملية شحن، لكن يمكنك التواصل مع مالك الحساب الذي قمت بالشحن له، و التفاوض معه لدفع المبلغ لك</p>
    </span>
    </div>
</template>

<script>

    export default {
        name: 'commonProblem',
        data () {
            return {

            }
        },
        methods: {

        }
    }
</script>

<style lang="scss">
    .commonProblem {
        text-align: right;
        span {
            display: block;
            width: 90vw;
            margin: auto;
            margin-top: 10vw;
            font-size: 16px;
            color: #333;
            p {
                color: #6d6f73;
                font-size: 13px;
            }
        }
    }
</style>