<template>
  <div class="service">
    <p>Whisper’s Terms of Service</p>
    <p>This Terms of Service (“Terms”) was written in English (US). This Terms is the legitimate agreement by and between You and Whisper ("Whisper", "we" or "Blagol Technology Co., Ltd"). To the extent any translated version of Terms agreement conflicts against the English version, this English version prevails.</p>
    <p>Tips:</p>
    <p>There is no tolerance for objectionable content or abusive users in Whisper, which will lead to block their ID or device as punishment. You can report these users, and Whisper will review in 24 hour about porn, violence, gambling and so on.</p>
    <p>Summary</p>
    <p>You should read Terms in full because they apply every time you visit Whisper, and is the terms of service that governs the relationship between the users who interact with Whisper, as well as Whisper brands, products and services, which We call the “Whisper Services” or “Services”. By using or accessing the Whisper Services, you agree to this Terms. However, just in case you ever need a reminder of the main points, here’s a quick basic Terms:</p>
    <p>1. You will not use Whisper Services if you are under 18.</p>
    <p>2.We’re not responsible for any materials that you post or address while you are using Whisper and We don’t monitor the contents in your personal devices, but if We find or someone alerts about your inappropriate activities, We will, in our sole discretions, remove such materials.</p>
    <p>3. You will not use Whisper to do anything unlawful, misleading, malicious, or discriminatory.</p>
    <p>The full legal bit</p>
    <p>Whisper is a free social Live app, which provides you to voice chat with your friends. In the app, you can find new friends, go Live, join a Live, send gifts. It is intended to be a fun place to visit and it is important to us (and for you) that it remains a safe and friendly environment so you agree that you will only use Whisper in a manner consistent with its purpose and which is in accordance with the Terms.</p>
    <p>The Terms apply whenever you use Whisper, whether or not you have chosen to register with us, so please read them carefully. By accessing, using, registering for or receiving services offered on Whisper (the “Services”) you are accepting and agreeing to be bound by the Terms.</p>
    <p>IF YOU DO NOT ACCEPT AND AGREE TO THE TERMS THEN YOU MUST NOT ACCESS OR USE THE APP OR SITE.</p>
    <p>1. Privacy</p>
    <p>Your privacy is very important to us. We designed our Privacy Policy to make important disclosures about how you can use Whisper to share with others and how we collect and can use your content and information. We encourage you to read the Privacy Policy, and to use it to help you make informed decisions.</p>
    <p>2. Sharing Your Content and Information</p>
    <p>You are able to post all kinds of things on Whisper, including photographs, messages and other content (“Content”). You own all of the content and information you post on Whisper. While there are some rules about what is acceptable though, so when you are using Whisper you may not post or send any Content which:</p>
    <p>a. contains the information, comment or any other forms of content about a person who is under 18 years old;</p>
    <p>b. contains expletives or language which could be deemed offensive or is likely to harass, upset, embarrass, alarm or annoy any other person;</p>
    <p>c. is obscene, pornographic or otherwise may offend human dignity;</p>
    <p>d. is abusive, insulting or threatening, or which promotes or encourages racism, sexism, hatred or bigotry;</p>
    <p>e. encourages any illegal activity including, without limitation, terrorism, inciting racial hatred or the submission of which in itself constitutes committing a criminal offence;</p>
    <p>f. is defamatory;</p>
    <p>g. relates to commercial activities (including, without limitation, sales, competitions and advertising, links to other websites or premium line telephone numbers);</p>
    <p>h. involves the transmission of “junk” mail or “spam”;</p>
    <p>i. contains any spy ware, viruses, corrupt files, worm programmes or other malicious code designed to interrupt, damage or limit the functionality of or disrupt any software, hardware, telecommunications, networks, servers or other equipment, Trojan horse or any other material designed to damage, interfere with, wrongly intercept or expropriate any data or personal information whether from Whisper or otherwise;</p>
    <p>j. itself, or the posting of which, infringes any third party’s rights (including, without limitation, intellectual property rights and privacy rights); or</p>
    <p>k. shows another person which was created or distributed without that person’s consent</p>
    <p>l. We always appreciate your feedback or other suggestions about Whisper, but you understand that we may use your feedback or suggestions without any obligation to compensate you for them (just as you have no obligation to offer them).</p>
    <p>m.Please use your common sense when picking the Content that you choose to post on or send via Whisper because you are solely responsible for, and bear all liability in relation to, such Content.</p>
    <p>3. Protecting Other People’s Rights</p>
    <p>We respect other people’s rights and do our best to keep Whisper safe, and expect you to do the same.</p>
    <p>a. You will not post content or take any action on Whisper that infringes or violates someone else’s rights or otherwise violates the law.</p>
    <p>b. We can remove any content or information you post on Whisper if we believe that it violates this Terms or our policies.</p>
    <p>c. If we remove your content for infringing someone else’s copyright, and you believe we removed it by mistake, we will provide you with an opportunity to appeal.</p>
    <p>d. If you repeatedly infringe other people’s intellectual property rights, we will disable your account when appropriate.</p>
    <p>e. You will not use our copyrights or Trademarks or any confusingly similar marks, except as expressly permitted by our Brand Usage Guidelines or with our prior written permission.</p>
    <p>f. If you collect information from users, you will: obtain their consent, make it clear you (and not Whisper) are the one collecting their information, and post a privacy policy explaining what information you collect and how you will use it.</p>
    <p>g. You will not post anyone’s identification documents or sensitive financial information on Whisper.</p>
    <p>h. You will not tag users or send email invitations to non-users without their consent.</p>
    <p>4. Registration and Account</p>
    <p>Whisper users required nick name, age, gender, avatar and other optional information, and we need your help to keep it that way. We provide two ways to access Whisper, Facebook and SMS. Here are some commitments you make to us relating to registering and maintaining the security of your account:</p>
    <p>a. You will not use Whisper if you are under 18.</p>
    <p>b. You will not transfer your account to anyone without first getting our written permission.</p>
    <p>c. You will not provide any false personal information on Whisper, or create an account for anyone other than yourself without permission.</p>
    <p>d. If we disable your account, you will not post any content on Whisper except contact with official assistant which we called “Whisper Team”.</p>
    <p>e. We reserve the right to destroy or ban your account if we believe it is appropriate.</p>
    <p>Termination</p>
    <p>If you violate the Terms, or otherwise create risk or possible legal exposure for us, then we reserve the right at our sole discretion, at any time and without liability or the need to give you prior notice or to refund any unused services to:</p>
    <p>a. Suspend or revoke your registration (where applicable) and your right to access and/or use Whisper or submit any Content to Whisper;</p>
    <p>b. Make use of any operational, technological, legal, or other means available to enforce the Terms (including without limitation blocking specific IP addresses).</p>
    <p>c. We will try (but we are not obliged) to notify you if your access to Whisper and/or your Profile is to be or has been suspended or terminated.</p>
    <p>d. If you have registered on Whisper, you can terminate your registration at any time by going to the “Settings” control on Whisper when you are logged in and choosing the “Delete Account” link. We save your profile information in case you later decide to restore your account. Many users deactivate their accounts for temporary reasons and in doing so expect us to maintain their information until they return to Whisper. You will therefore be able to restore your account and your profile in its entirety within 30 days of de-activating it. Once your registration is terminated, we have the right to delete any related Content that you have submitted or uploaded on to Whisper. For further details, please refer to our Privacy Policy. Content that you have uploaded to Whisper, other than your Profile (such as Moments or Comments or Contact), may still appear on Whisper following termination of your registration.</p>
    <p>5. Proprietary Rights in Content on Whisper</p>
    <p>a. Whisper does NOT claim ANY ownership rights in the text, files, images, photos, video, sounds, musical works, works of authorship, applications, or any other materials (collectively, “Content”) that you post on or through the Whisper Services. By displaying or publishing (“posting”) any Content on or through the Whisper Services, you hereby grant to Whisper a non-exclusive, fully paid and royalty-free, worldwide, limited license to use, modify, delete from, add to, publicly perform, publicly display, reproduce and translate such Content, including without limitation distributing part or all of the Site in any media formats through any media channels, except Content not shared publicly (“private”) will not be distributed outside the Whisper Services.</p>
    <p>b. You represent and warrant that: (i) you own the Content posted by you on or through the Whisper Services or otherwise have the right to grant the license set forth in this section, (ii) the posting and use of your Content on or through the Whisper Services does not violate the privacy rights, publicity rights, copyrights, contract rights, intellectual property rights or any other rights of any person, and (iii) the posting of your Content on the Site does not result in a breach of contract between you and a third party. You agree to pay for all royalties, fees, and any other monies owing any person by reason of Content you post on or through the Whisper Services.</p>
    <p>c. The Whisper Services contain Content of Whisper (“Whisper Content”). WhisperContent is protected by copyright, trademark, patent, trade secret and other laws, and Whisper owns and retains all rights in the Whisper Content and the Whisper Services. Whisper hereby grants you a limited, revocable, nonsublicensable license to reproduce and display the Whisper Content (excluding any software code) solely for your personal use in connection with viewing the Site and using the Whisper Services.</p>
    <p>d. The Whisper Services contain Content of Users and other Whisper licensors. Except as provided within this Agreement, you may not copy, modify, translate, publish, broadcast, transmit, distribute, perform, display, or sell any Content appearing on or through the Whisper Services.</p>
    <p>e. Whisper performs technical functions necessary to offer the Whisper Services, including but not limited to transcoding and/or reformatting Content to allow its use throughout the Whisper Services.</p>
    <p>f. Although the Site and other Whisper Services are normally available, there will be occasions when the Site or other Whisper Services will be interrupted for scheduled maintenance or upgrades, for emergency repairs, or due to failure of telecommunications links and equipment that are beyond the control of Whisper. Also, although Whisper will normally only delete Content that violates this Agreement, Whisper reserves the right to delete any Content for any reason, without prior notice. Deleted content may be stored by Whisper in order to comply with certain legal obligations and is not retrievable without a valid court order. Consequently, Whisper encourages you to maintain your own backup of your Content. In other words, Whisper is not a backup service. Whisper will not be liable to you for any modification, suspension, or discontinuation of the Whisper Services, or the loss of any Content.</p>
    <p>6. Disclaimer of warranties and limitation of liability</p>
    <p>To the fullest extent permitted by law, Whisper expressly excludes:</p>
    <p>a. all conditions, representations, warranties and other terms which might otherwise be implied by statute, common law or the law of equity; and</p>
    <p>b. any liability incurred by you arising from use of Whisper, its services or these terms and conditions, including without limitation for any claims, charges, demands, damages, liabilities, losses or expenses of whatever nature and howsoever direct, indirect, incidental, special, exemplary, punitive or consequential damages (however arising including negligence), loss of use, loss of data, loss caused by a computer or electronic virus, loss of income or profit, loss of or damage to property, wasted management or office time, breach of contract or claims of third parties or other losses of any kind or character, even if Whisper has been advised of the possibility of such damages or losses, arising out of or in connection with the use of Whisper. This limitation on liability applies to, but is not limited to, the transmission of any disabling device or virus that may infect your equipment, failure or mechanical or electrical equipment or communication lines, telephone or other interconnect problems (e.g., you cannot access your internet service provider), unauthorized access, theft, bodily injury (other than caused by our negligence), property damage, operator errors, strikes or other labor problems or any act of god in connection with Whisper including but not limited to any liability for loss of revenue or income, loss of profits or contracts, loss of business, loss of anticipated savings, loss of goodwill, loss of data, wasted management or office time and any other loss or damage of any kind, however arising and whether caused by tort (including, but not limited to, negligence), breach of contract or otherwise, even if foreseeable whether arising directly or indirectly.</p>
    <p>c. For clearance of doubt, although Whisper is under no obligation to assumes responsibility or liability arising from the activities you are engaging, nor for any error, defamation, libel, slander, omission, falsehood, obscenity, pornography, profanity, danger, or inaccuracy contained in any information while you are using Service. You are prohibited from posting or transmitting any unlawful, threatening, libelous, defamatory, obscene, scandalous, inflammatory, pornographic, or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability, or otherwise violate any law. Whisper will fully cooperate with any law enforcement authorities or court order requesting or directing Whisper to disclose the identity of any user that is engaging in any illegal activities.</p>
    <p>7. Indemnity</p>
    <p>You agree to defend, indemnify and hold harmless Whisper, its parent corporation, officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees) arising from: (i) your use of and access to the Whisper Service; (ii) your violation of any term of these Terms; (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that one of your User Status Submissions caused damage to a third party. This defense and indemnification obligation will survive this Terms and your use of the Whisper Service. We don’t support or encourage illegal consumption of alcohol or tobacco, so there.</p>
    <p>8. Amendments</p>
    <p>We reserve the right to make changes to these terms and give you the opportunity to review and comment on the revised terms before continuing to use our Services. we may provide notice on the Website if we make changes to policies, guidelines or other terms referenced in or incorporated by this Statement.</p>
    <p>9. Contact Us</p>
    <p>Blagol Technology Co., Ltd</p>
    <p>Address: RM502C, 5/F, HO KING COMM CTR, 2-16 FAYUEN ST, MONGKOK KOWLOON, HONG KONG</p>
    <p>Governing law：Hongkong Laws</p>
    <p>Email:whisperfeedback@gmail.com</p>
  </div>
</template>

<script>

export default {
  name: 'service',
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
.service {
  padding-bottom: 10vw;
  p {
    display: block;
    width: 90vw;
    margin: auto;
    text-align: left;
    padding: 5vw 0 0 0;
    color: #6d6f73;
    font-size: 14px;
  }
}
</style>
