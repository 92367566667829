export default({
    data(){
        return {            
            //付款明细
            payDetails: { 
                1: 'Payment Detail', 
                2: 'تفاضيل الدفع',
                3: 'Ödeme detayları'
            },
            //金币文案
            coinsText: {
                1: 'coins',
                2: 'كوينز',
                3: 'jeton'
            },
            //金额文案
            amountText: {
                1: 'Amount',
                2: 'مبلغ الدفع',
                3: 'Miktar'
            },      
            ordernumberText: {
                1: 'Order number',
                2: 'رقم الطلب',
                3: 'Sipariş numarası'
            },
            //您的设备不可用
            devicenotAvailable: {
                1: 'Not available for your device',
                2: 'غير متاح للجهاز الخاص بك',        
                3: 'Not available for your device',
            },
            daysText: {
                1: 'day',
                2: 'يوم',                    
                3: 'günler' ,
            },
            daysText2: {
                1: 'days',
                2: 'يوم',                    
                3: 'günler' ,
            },
            usdTexts: {
                1: 'USD',
                2: 'USD',
                3: 'USD',
            },
            offerTexts: {
                1: 'offer',
                2: 'عرض',
                3: 'teklif'
            },
            successfulText: {
                1: 'Recharge successful',
                2: 'تم الشحن بنجاح',
                3: 'Şarj etmek başarılı'
            },
            doneText: {
                1: 'Done',
                2: 'تم',
                3: 'Tamamlamak'
            },
            paymentText: {
                1: 'Payment amount',
                2: 'مبلغ الدفع', 
                3: 'Ödeme miktarı'
            },
            passText: {
                // 1: 'Luxury Pass',
                // 2: 'الباس الخافر',
                // 3: 'Lüks Pass'
                1: 'Purchase Luxury Pass',
                2: 'شراء الباس الفاخر',
                3: 'Lüks Pass Satın Almak '
            },
            purchaseText: {
                1: 'Purchase coins',
                2: 'الكوينزات',
                3: 'Jeton satın almak'
            },
            purchasegiftText: {
                1: 'Purchase price',
                2: 'سعر الشراء',
                3: 'Sarın alma fiyatı',
            },
            coinsText: {
                1: 'coins',
                2: 'كوينز',
                3: 'jeton'
            },
            purchasetimesText: {
                1: 'Payment time',
                2: 'وقت الدفع',
                3: 'Ödeme zamanı'
            },
            ordernumberText: {
                1: 'Order number',
                2: 'رقم الطلب',
                3: 'Sipariş numarası'
            },
            buygiftpackNames: {
                1:'Offer name',  
                2:'اسم العرض',
                3:'Teklif adı'
            }, //this.$t('m.name')
            rwchargeTypeTexts: {
                0: {
                    1:'coins',  
                    2:' كوينز',
                    3:' jeton'
                },
                1: {
                    1:'Offer name',  
                    2:'اسم العرض',
                    3:'Teklif adı'
                }, //this.$t('m.name')
                2: {
                    1:'coins',  
                    2:' كوينز',
                    3:' jeton'
                }, //this.$t('m.coins')
            },
        }
    }
})