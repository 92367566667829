<template>
  <div class="refund">
    <p>
      Refunding is not accepted, if you recharge for wrong ID, we suggest you to contact the owner of ID you recharged for, and negotiate with him to pay you the amount.
    </p>
  </div>
</template>

<script>

export default {
  name: 'refund',
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
.refund {
  padding-bottom: 10vw;
  p {
    display: block;
    width: 90vw;
    margin: auto;
    text-align: left;
    padding: 5vw 0 0 0;
    color: #6d6f73;
    font-size: 14px;
  }
}
</style>
