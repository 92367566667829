<template>
  <div class="nobleFrame_er">
    <div class="topTitle">
      <van-icon name="arrow" @click="toHome()" /><span>أرستقراطية</span>
    </div>
    <div class="contentTab">
      <van-tabs v-model="active" title-active-color="#e7b772">
        <van-tab title="نبيل">
          <nobleLv1></nobleLv1>
        </van-tab>
        <van-tab title="جنرال">
          <nobleLv2></nobleLv2>
        </van-tab>
        <van-tab title="وزير">
          <nobleLv3></nobleLv3>
        </van-tab>
        <van-tab title="دوق">
          <nobleLv4></nobleLv4>
        </van-tab>
        <van-tab :title="tab5Title" >
          <nobleLv5 :title="tab5Title" @changeTabtitle="getTabtitle"></nobleLv5>
        </van-tab>
        <van-tab title="إمبراطور">
          <nobleLv6></nobleLv6>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>


<script>
import nobleLv1 from '@/components/noble_er/nobleLv1'
import nobleLv2 from '@/components/noble_er/nobleLv2'
import nobleLv3 from '@/components/noble_er/nobleLv3'
import nobleLv4 from '@/components/noble_er/nobleLv4'
import nobleLv5 from '@/components/noble_er/nobleLv5'
import nobleLv6 from '@/components/noble_er/nobleLv6'
export default {
  name: 'noble_er',
  components: {
    nobleLv1: nobleLv1,
    nobleLv2: nobleLv2,
    nobleLv3: nobleLv3,
    nobleLv4: nobleLv4,
    nobleLv5: nobleLv5,
    nobleLv6: nobleLv6
  },
  data () {
    return {
      active: 0,
      tab5Title: "ملك"
    }
  },
  methods: {
    toHome () {
      this.$router.push('/ar')
    },
    getTabtitle(val){
      this.tab5Title = val
    }
  }
}
</script>

<style lang="scss" scoped>
.nobleFrame_er {
  direction: rtl;
  .topTitle {
    background: #292c31;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 4vw 0;
    .van-icon {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      margin: 0 4vw 0 30vw;
    }
  }
  .contentTab {
    .van-tabs__nav {
      background: #292c31;
    }
    .van-tab {
      font-weight: bold;
      color: #ffffff4d;
    }
    .van-tab--active {
      color: #e7b772;
      font-weight: bold;
    }
    .van-tabs__line {
      background: #e7b772;
      width: 13px;
      bottom: 21px;
    }
  }
}
</style>