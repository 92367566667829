<template>
  <div class="about">

    <img class="logo" src="../assets/imgs/logo2.png" alt="">
    <img class="text" src="../assets/imgs/whisperText2.png" alt="">
    <span class="text2">Chat & Party</span>
    <ul>
      <li @click="clickPage('/service')">
        <span>Terms of Service</span>
        <img src="../assets/imgs/right.png" alt="">
      </li>
      <li @click="clickPage('/privacy')">
        <span>Privacy Policy</span>
        <img src="../assets/imgs/right.png" alt="">
      </li>
      <li @click="clickPage('/refund')">
        <span>Refund Policy</span>
        <img src="../assets/imgs/right.png" alt="">
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'about',
  data () {
    return {

    }
  },
  methods: {
    clickPage (page) {
      this.$router.push(page)
    }
  }
}
</script>

<style lang="scss">
.about {
  .logo {
    width: 30vw;
    padding: 10vw 0 5vw 0;
  }
  .text {
    height: 10vw;
    display: block;
    margin: auto;
  }
  .text2 {
    color: #333;
    font-size: 14px;
    display: block;
  }
  ul {
    margin-top: 5vw;
    li {
      width: 90%;
      text-align: left;
      display: flex;
      align-items: center;
      padding: 4vw 0;
      margin: auto;
      span {
        display: inline-block;
        font-size: 16px;
        width: 90vw;
      }
      img {
        width: 16px;
      }
    }
    li:nth-child(2) {
      border-top: 0.5px solid #dbdbdb;
      border-bottom: 0.5px solid #dbdbdb;
    }
  }
}
</style>
