import https from "https";
<template>
  <div class="credit" :dir="dir">
    <div class="whisper" dir="ltr">
      <img class="logo" src="../assets/imgs/logo2.png" alt="" />
      <img class="text" src="../assets/imgs/whisperText2.png" alt="" />
    </div>
    <div class="order">
      <p class="title" v-if="lang === 1">Payment Detail</p>
      <p class="title" v-else-if="lang === 3">Ödeme detayları</p>
      <p class="title" v-else>تفاضيل الدفع</p>
      <!-- 购买通行证 -->
      <template v-if="good_id === 'good_19'">
        <ul>
          <li class="item">
            <div class="key">{{ $t('m.pass') }}</div>
            <!-- <div class="key" v-if="lang === 1">{{ desc }}</div>
            <div class="key" v-else-if="lang === 3">{{ desc }}</div>
            <div class="key" v-else>الكوينزات</div> -->
            <div class="value">
              {{ `${queryData.first_month_day}-${queryData.last_month_day}` }}
            </div>
          </li>
        </ul>
      </template>
      <template v-else>
        <ul>
          <li class="item">
            <!-- 购买产品类型名称 -->
            <div class="key" v-if="lang === 1 && rwchargeType === 0">Coins</div>
            <div class="key" v-else-if="lang === 2 && rwchargeType === 0">الكوينزات</div>
            <div class="key" v-else-if="lang === 3 && rwchargeType === 0">Jeton </div>
            <div class="key" v-else>{{ rwchargeTypeTexts[rwchargeType]}}</div>
           
            <!-- 购买产品具体信息 -->   
            <div class="value" v-if="rwchargeType === 0">{{ coins }}</div>
            <div class="value" v-else-if="rwchargeType=== 1 && Number(valid_day)<=1">{{ $t('m.giftandday', {m: offername + " " + 'USD', n: valid_day}) }}</div>
            <div class="value" v-else-if="rwchargeType=== 1 && Number(valid_day)>1">{{ $t('m.giftandday1', {m: offername + " " + 'USD', n: valid_day}) }}</div>
            <div class="value" v-else>{{ coins }}</div>
          </li>
        </ul>
      </template>
      <ul>
        <li class="item">
          <div class="key" v-if="lang === 1">Amount</div>
          <div class="key" v-else-if="lang === 3">Miktar</div>
          <div class="key" v-else>مبلغ الدفع</div>
          <div class="value">{{ amount + " " + currency }}</div>
        </li>
      </ul>
      <ul>
        <li class="item">
          <div class="key" v-if="lang === 1">Order Number</div>
          <div class="key" v-else-if="lang === 3">Sipariş numarası</div>
          <div class="key" v-else>رقم الطلب</div>
          <div class="value">{{ Number(code) === 0 ? payment_id : code }}</div>
        </li>
      </ul>
    </div>

    <div class="payment">
      <p class="payment-title" v-if="lang === 1">Payment</p>
      <p class="payment-title" v-else-if="lang === 3">Ödeme</p>
      <p class="payment-title" v-else>الدفع</p>
      <div class="payment-icons">
        <img class="payment-icon" src="../assets/imgs/pay2.png" alt="" />
        <img class="payment-icon" src="../assets/imgs/pay3.png" alt="" />
        <img class="payment-icon" src="../assets/imgs/pay4.png" alt="" />
        <img class="payment-icon" src="../assets/imgs/pay5.png" alt="" />
        <img class="payment-icon" src="../assets/imgs/pay6.png" alt="" />
        <img class="payment-icon" src="../assets/imgs/pay7.png" alt="" />
      </div>
    </div>

    <!--        <remote-js src="https://cdn.checkout.com/js/framesv2.min.js"></remote-js>-->
    <form id="payment-form" method="POST" action="https://merchant.com/charge-card">
      <!--            <label for="card-number">Card number</label>-->
      <div class="input-container card-number">
        <div class="icon-container">
          <img id="icon-card-number" src="images/card-icons/card.svg" alt="PAN" />
        </div>
        <div class="card-number-frame"></div>
        <div class="icon-container payment-method">
          <img id="logo-payment-method" />
        </div>
        <div class="icon-container">
          <img id="icon-card-number-error" src="images/card-icons/error.svg" />
        </div>
      </div>

      <div class="date-and-code">
        <div>
          <!--                    <label for="expiry-date">Expiry date</label>-->
          <div class="input-container expiry-date">
            <div class="icon-container">
              <img id="icon-expiry-date" src="images/card-icons/exp-date.svg" alt="Expiry date" />
            </div>
            <div class="expiry-date-frame"></div>
            <div class="icon-container">
              <img id="icon-expiry-date-error" src="images/card-icons/error.svg" />
            </div>
          </div>
        </div>

        <div>
          <!--                    <label for="cvv">Security code</label>-->
          <div class="input-container cvv">
            <div class="icon-container">
              <img id="icon-cvv" src="images/card-icons/cvv.svg" alt="CVV" />
            </div>
            <div class="cvv-frame"></div>
            <div class="icon-container">
              <img id="icon-cvv-error" src="images/card-icons/error.svg" />
            </div>
          </div>
        </div>
      </div>
      <div class="input-container cardholder">
        <div class="icon-container">
          <img id="icon-cardholder" src="images/card-icons/user.svg" alt="PAN" />
        </div>
        <div class="cardholder-frame frame--activated" dir="ltr">
          <input class="cardholder-input" placeholder="Cardholder name" v-model="cardholder" />
        </div>
      </div>

      <button id="pay-button" disabled="">
        {{ payObj[lang] }}
      </button>

      <div>
        <span class="error-message error-message__card-number"></span>
        <span class="error-message error-message__expiry-date"></span>
        <span class="error-message error-message__cvv"></span>
        <span class="error-message error-message__cardholder"></span>
      </div>

      <p class="success-payment-message"></p>
      <VanLoading size="40px" v-show="loading" class="loading" color="#ffffff" type="spinner" vertical>
        <div class="loading-text"></div>
      </VanLoading>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import https from "https";
import { BASE_URL, CKO_PUBLIC_KEY } from "../assets/js/config";
import sendPageView from "../assets/js/sift";

export default {
  name: "Credit",
  // components: {
  //     'remote-js': {
  //         render(createElement) {
  //             return createElement('script', { attrs: { type: 'text/javascript', src: this.src }});
  //         },
  //         props: {
  //             src: { type: String, required: true },
  //         },
  //     },
  // },
  data () {
    return {
      payObj: {1: "PAY", 2: "دفع", 3: "ÖDEME"},
      token: "",
      bin: "",
      // uid: this.$route.params.uid,
      offername: this.$route.query.objective,
      coins: this.$route.query.coins,
      amount: this.$route.query.amount,
      currency: this.$route.query.currency || this.$route.query.data.currency,
      code: this.$route.query.code,
      payment_id: this.$route.query.payment_id || this.$route.query.data.payment_id,
      pay_method: this.$route.query.pay_method,
      good_id: this.$route.query.good_id,  //商品id
      offer_id: this.$route.query.offer_id,  //礼包id
      desc: this.$route.query.desc, //通行证
      valid_day: this.$route.query.valid_day, //有效期
      queryData: this.$route.query,
      rwchargeType: Number(this.$route.query.rwchargeType) || 0, //礼包的充值类型 1：礼包充值  2：礼包下金币充值 (0-原先金币和通行证 1-礼包购买 2-礼包内充值)
      rwchargeTypeTexts: {
        1: this.$t('m.name'),  //礼包名称
        2: this.$t('m.coins')  //金币
      },

      loading: false,
      Frames: "",
      cardholder: "",
    };
  },
  computed: {
    lang () {
      let lang = this.$route.query.lang;
      return lang ? parseInt(lang) : 1;
    },
    dir () {
      return this.lang !== 2 ? "ltr" : "rtl";
    },
  },
  created () { 
    //console.log('------------------------- 确认付款填写资料页 - 信用卡 ---------------------- ')
    console.log('Credit卡支付路由参数.................', JSON.parse(JSON.stringify(this.$route.query)), )
    //////console.log('当前语言..........', this.lang)
    this.seti18nLang()
  },
  mounted () {
    sendPageView();
    let Frames = window.Frames;
    // //////console.log('Frames..................', Frames)
    let payButton = document.getElementById("pay-button");
    let form = document.getElementById("payment-form");
    Frames.init(CKO_PUBLIC_KEY);

    let logos = generateLogos();
    // //////console.log('logos======', logos)

    function generateLogos () {
      let logos = {};
      logos["card-number"] = {
        src: "card",
        alt: "card number logo",
      };
      logos["expiry-date"] = {
        src: "exp-date",
        alt: "expiry date logo",
      };
      logos["cvv"] = {
        src: "cvv",
        alt: "cvv logo",
      };
      return logos;
    }

    let errors = {};
    errors["card-number"] = "Please enter a valid card number";
    errors["expiry-date"] = "Please enter a valid expiry date";
    errors["cvv"] = "Please enter a valid cvv code";
    errors["cardholder"] = "Please enter a valid cardholder name";

    Frames.removeAllEventHandlers(Frames.Events.FRAME_VALIDATION_CHANGED); //frame validation changed

    Frames.addEventHandler(
      Frames.Events.FRAME_VALIDATION_CHANGED,
      onValidationChanged
    );

    function onValidationChanged (event) {
      let e = event.element;

      if (event.isValid || event.isEmpty) {
        if (e === "card-number" && !event.isEmpty) {
          showPaymentMethodIcon();
        }
        setDefaultIcon(e);
        clearErrorIcon(e);
        clearErrorMessage(e);
      } else {
        if (e === "card-number") {
          clearPaymentMethodIcon();
        }
        setDefaultErrorIcon(e);
        setErrorIcon(e);
        setErrorMessage(e);
      }
    }

    function clearErrorMessage (el) {
      let selector = ".error-message__" + el;
      let message = document.querySelector(selector);
      message.textContent = "";
    }

    function clearErrorIcon (el) {
      let logo = document.getElementById("icon-" + el + "-error");
      logo.style.removeProperty("display");
    }

    function showPaymentMethodIcon (parent, pm) {
      if (parent) parent.classList.add("show");

      let logo = document.getElementById("logo-payment-method");
      if (pm) {
        let name = pm.toLowerCase();
        logo.setAttribute("src", "images/card-icons/" + name + ".svg");
        logo.setAttribute("alt", pm || "payment method");
      }
      logo.style.removeProperty("display");
    }

    function clearPaymentMethodIcon (parent) {
      if (parent) parent.classList.remove("show");

      let logo = document.getElementById("logo-payment-method");
      logo.style.setProperty("display", "none");
    }

    function setErrorMessage (el) {
      let selector = ".error-message__" + el;
      let message = document.querySelector(selector);
      message.textContent = errors[el];
    }

    function setDefaultIcon (el) {
      let selector = "icon-" + el;
      let logo = document.getElementById(selector);
      logo.setAttribute("src", "images/card-icons/" + logos[el].src + ".svg");
      logo.setAttribute("alt", logos[el].alt);
    }

    function setDefaultErrorIcon (el) {
      let selector = "icon-" + el;
      let logo = document.getElementById(selector);
      logo.setAttribute(
        "src",
        "images/card-icons/" + logos[el].src + "-error.svg"
      );
      logo.setAttribute("alt", logos[el].alt);
    }

    function setErrorIcon (el) {
      let logo = document.getElementById("icon-" + el + "-error");
      logo.style.setProperty("display", "block");
    }

    Frames.removeAllEventHandlers(Frames.Events.CARD_VALIDATION_CHANGED); //card validation changed
    Frames.addEventHandler(
      Frames.Events.CARD_VALIDATION_CHANGED,
      cardValidationChanged
    );

    function cardValidationChanged () {
      payButton.disabled = !Frames.isCardValid();
    }

    Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZATION_FAILED);
    Frames.addEventHandler(
      Frames.Events.CARD_TOKENIZATION_FAILED,
      this.onCardTokenizationFailed
    );

    // function onCardTokenizationFailed (error) {
    //   alert(error.message)
    //   //////console.log(error)
    //   //////console.log("CARD_TOKENIZATION_FAILED: %o", error);
    //   Frames.enableSubmitForm();
    // }

    Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZED);
    Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, this.onCardTokenized);

    Frames.removeAllEventHandlers(Frames.Events.PAYMENT_METHOD_CHANGED);
    Frames.addEventHandler(
      Frames.Events.PAYMENT_METHOD_CHANGED,
      paymentMethodChanged
    );

    function paymentMethodChanged (event) {
      let pm = event.paymentMethod;
      let container = document.querySelector(".icon-container.payment-method");

      if (!pm) {
        clearPaymentMethodIcon(container);
      } else {
        clearErrorIcon("card-number");
        showPaymentMethodIcon(container, pm);
      }
    }

    form.addEventListener("submit", this.onSubmit);
  },
  methods: {
    /**
     * @param 设置i18n语言
     * zh-EN  zh-ER  zh-TR
    */
    seti18nLang() {
        let routeobj = {
            1: {
                path: '/',
                langType: 'zh-EN'
            },
            2: {
                path: '/ar',
                langType: 'zh-ER'
            },
            3: {
                path: '/tr',
                langType: 'zh-TR'
            }
        }
        this.$i18n.locale = routeobj[this.lang].langType
        sessionStorage.setItem('lang', this.lang)
    },
    onSubmit (event) {
      event.preventDefault();
      // //////console.log(this.cardholder);
      if (!this.cardholder || this.cardholder.length > 200) {
        this.setErrorMessage(
          "cardholder",
          "Please enter a valid cardholder name"
        );
      } else {
        Frames.submitCard();
        this.loading = true;
      }
    },
    setErrorMessage (el, msg) {
      let selector = ".error-message__" + el;
      let message = document.querySelector(selector);
      message.textContent = msg;
    },
    onCardTokenizationFailed (error) {
      this.loading = false;
      this.$toast(error.message);
      //////console.log("CARD_TOKENIZATION_FAILED: %o", error);
      Frames.enableSubmitForm();
    },
    onCardTokenized (event) {
      // let el = document.querySelector(".success-payment-message");
      // el.innerHTML =
      //     "Card tokenization completed<br>" +
      //     'Your card token is: <span class="token">' +
      //     event.token +
      //     "</span>";
      // //////console.log(event, 'event');
      this.loading = true;

      this.token = event.token;
      this.bin = event.bin;
      this.pay(event);
    },
    /**
     * 充值
     * @param rwchargeType  0-原先金币和通行证 1-礼包购买 2-礼包内充值
     * */
    pay (event) {
      let data = {
        token: this.token,
        bin: this.bin,
        // bin: '530060',
        // last4: event.last4,
        // payment_type: event.type,
        // payment_gateway: event.scheme,
        card_token: event,
        payment_id: this.payment_id,
        pay_method: this.pay_method,
        cardholder: this.cardholder,
        lang: this.lang,
        session_id: window._session_id || "",
        pay_type: this.rwchargeType, //# pay_type 0-原先金币和通行证 1-礼包购买 2-礼包内充值
      }
      
      let url = BASE_URL + "/pay/checkout/payment/pay";
      axios.post(url, data, {
        httpsAgent: new https.Agent({ rejectUnauthorized: false }),
      })
      .then((res) => {
        // //////console.log(res,'res')
        this.loading = false;
        if (res.status !== 200) {
          this.$router.push({ name: "fail" });
        } else {
          //////console.log(res, '............支付结果')
          if (res.data.code === 0) {
            res.data.data.lang = this.lang;
            let jumpsuccessParams = {...this.queryData, ...res.data.data, rwchargeType: this.rwchargeType}
            console.log(jumpsuccessParams, '........信用卡支付跳支付成功页面及传参')
            this.$router.push({ name: "success", query: jumpsuccessParams });
          } else if (res.data.code === 202) {
            let redirect = res.data.data.redirect;
            console.log(redirect, '.........信用卡支付redirect回调链接')
            window.location.href = redirect;
          } else {
            console.log('.........信用卡支付失败跳失败页面')
            this.$router.push({ name: "fail", query: { lang: this.lang } });
          }
        }
      })
      .catch(err => {
        //////console.log(err, '............支付异常')
      })

      //console.log('..............支付传参', JSON.parse(JSON.stringify(data)))
      
      // if(this.rwchargeType === 0){
      //     data = {
      //       token: this.token,
      //       bin: this.bin,
      //       // bin: '530060',
      //       // last4: event.last4,
      //       // payment_type: event.type,
      //       // payment_gateway: event.scheme,
      //       card_token: event,
      //       payment_id: this.payment_id,
      //       pay_method: this.pay_method,
      //       cardholder: this.cardholder,
      //       lang: this.lang,
      //       session_id: window._session_id || "",
      //       pay_type: this.rwchargeType, //# pay_type 0-原先金币和通行证 1-礼包购买 2-礼包内充值
      //       // offer_id: ""
      //     }
      // } else {
      //     data = {
      //       token: this.token,
      //       bin: this.bin,
      //       // bin: '530060',
      //       // last4: event.last4,
      //       // payment_type: event.type,
      //       // payment_gateway: event.scheme,
      //       card_token: event,
      //       payment_id: this.payment_id,
      //       pay_method: this.pay_method,
      //       cardholder: this.cardholder,
      //       lang: this.lang,
      //       session_id: window._session_id || "",
      //       pay_type: this.rwchargeType, //# pay_type 0-原先金币和通行证 1-礼包购买 2-礼包内充值
      //       // offer_id: this.offer_id, //# pay_type为2时必传
      //       // good_id: this.good_id
      //     }
      // }
      // if(this.rwchargeType === 1) {
      //   delete data.good_id
      // }

    },
  },
};
</script>

<style scoped>
.credit {
  text-align: left;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 121;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  vertical-align: middle;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-text {
  color: #ffffff;
}

.whisper {
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whisper .logo {
  height: 10vw;
}

.whisper .text {
  width: auto;
  height: 8vw;
  margin: 0 1vw;
}

.order {
  margin: 40px 30px 0;
}

.order .title {
  /*text-align: right;*/
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  border-bottom: 1px solid rgba(105, 120, 135, 0.3);
  height: 32px;
  font-size: 20px;
}

.order .item {
  height: 28px;
  display: flex;
  /*flex-direction: ;*/
  justify-content: space-between;
  width: 100%;
}

.item .key {
  /*text-align: right;*/
  font-weight: bold;
}

.item .value {
  margin-right: 0;
  text-align: right;
  font-size: 16px;
}

.payment {
  margin: 30px 30px 0;
  text-align: left;
}

.payment-title {
  /*text-align: right;*/
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  color: #6d6f73;
  border-bottom: 1px solid rgba(105, 120, 135, 0.3);
  height: 28px;
  font-size: 20px;
}

.payment-icons {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.payment-icon {
  width: 44px;
  height: 23px;
  border: 1px solid #697887;
  border-radius: 2px;
  margin: 2px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  padding: 1rem;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

iframe {
  /* This fixes a mobile Safari bug */
  height: 38px !important;
}

#payment-form {
  /*width: 280px;*/
  margin: 20px 30px 0;
}

label {
  display: block;
  height: 10px;
  color: #13395e;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  margin: 8px 0;
}

.date-and-code {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
}

.date-and-code > div:nth-child(1) {
  /*width: 55.715%;*/
  width: 54%;
}

.date-and-code > div:nth-child(2) {
  width: 45.719%;
}

.input-container {
  position: relative;
  display: flex;
  height: 40px;
}

.icon-container:last-child {
  right: 0;
}

.icon-container.payment-method {
  right: 0;
}

.input-container.card-number {
  margin-bottom: 8px;
}

.input-container.expiry-date {
  /*margin-right: 4px;*/
  /*margin-left: 4px;*/
}

.input-container.cvv {
  /*margin-left: 4px;*/
  /*margin-right: 4px;*/
}

.card-number-frame {
  padding-left: 40px;
  width: 100%;
}

.cardholder-frame {
  padding-left: 40px;
  width: 100%;
}

.cardholder-input {
  padding: 0;
  margin: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  /*font: 400 13.3333px Arial;*/
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  color: #13395e;
  -webkit-text-fill-color: #13395e;
  width: 100%;
  letter-spacing: -0.4px;
  /*font-family: monospace;*/
}

.expiry-date-frame {
  padding-left: 40px;
}

.cvv-frame {
  padding-left: 40px;
}

div + button {
  margin-top: 8px;
}

.icon-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 26px;
  margin: 0 7px;
}

.icon-container.payment-method {
  transform: translateY(-50%) rotateY(90deg);
  transition: opacity 0.15s ease-out;
  opacity: 0;
  top: 50%;
}

.icon-container.payment-method.show {
  opacity: 1;
  transition: all 0.4s ease-out;
  transform: translateY(-50%) rotateY(0deg);
}

.icon-container.payment-method img {
  width: 100%;
}

[id$="-error"] {
  display: none;
}

.frame {
  opacity: 0;
}

.frame--activated {
  opacity: 1;
  border: solid 1px #13395e;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
}

.frame--activated.frame--focus {
  border: solid 1px #13395e;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
}

.frame--activated.frame--invalid {
  border: solid 1px #d96830;
  box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
}

.error-message {
  display: block;
  color: #c9501c;
  font-size: 0.9rem;
  margin: 8px 0 0 1px;
  font-weight: 300;
}

#pay-button {
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  height: 40px;
  width: 100%;
  background-color: #13395e;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:active {
  background-color: #0b2a49;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:hover {
  background-color: #15406b;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:disabled {
  background-color: #697887;
  box-shadow: none;
}

#pay-button:not(:disabled) {
  cursor: pointer;
}

.success-payment-message {
  color: #13395e;
  line-height: 1.4;
}

.token {
  color: #b35e14;
  font-size: 0.9rem;
  font-family: monospace;
}

/**
        IE11-targeted optimisations
         */
_:-ms-fullscreen,
:root .icon-container {
  display: block;
}

_:-ms-fullscreen,
:root .icon-container img {
  top: 50%;
  -ms-transform: translateY(-50%);
  position: absolute;
}

_:-ms-fullscreen,
#icon-card-number,
_:-ms-fullscreen,
#icon-expiry-date,
_:-ms-fullscreen,
#icon-cvv {
  left: 7px;
}

#checkout-frames-card-number::-ms-clear {
  display: none;
}
</style>