<template>
  <div class="commonProblem">
    <span>
      <b>1. Yeniden şarj ettikten sonra jetonlarımı nereden kontrol edebilirim?</b><br>
      <p>Şarj ettikten sonra, Whisper uygulamasını açabilir ve "Ben"-"Şarj jetonları" de sayfası jetonlarınızı kontrol edebilirsiniz</p>
    </span>
    <span>
      <b>2. Şarj işlemi tamamlandıktan sonra jeton almazsam ne olur?</b><br>
      <p>WhatsApp: +201032127473 ile iletişime geçebilir ve bilgi verebilirsiniz: 1 şarj miktarı.  2 şarj tarihi ve saati 3 şarj faturasının ekran görüntüsü. Siparişi onaylamcağız ve bundan sonra size jeton göndereceğiz.</p>
    </span>
    <span>
      <b>3. Yanlış ID için şarj edersem?</b><br>
      <p>Üzgünüz, herhangi bir yeniden yüklemeden sonra geri şarj yapamazsınız, ancak yeniden yükleme yaptığınız ID’in sahibiyle iletişime geçebilir ve size tutarı ödemesi için onunla görüşebilirsiniz.</p>
    </span>
  </div>
</template>

<script>

export default {
  name: 'commonProblemTR',
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
.commonProblem {
  text-align: left;
  span {
    display: block;
    width: 90vw;
    margin: auto;
    margin-top: 10vw;
    font-size: 16px;
    color: #333;
    p {
      color: #6d6f73;
      font-size: 13px;
    }
  }
}
</style>