<template>
    <div id="success" class="success" :dir="dir">
        <img src="../assets/imgs/success.png" alt="">
        <span class="title">{{ successfulText[lang] }}</span>
        <ul>            
            <!-- 支付金额 -->
            <li class="payment_amount">
                <span class="listTitle">{{ purchasegiftText[lang] }}</span>
                <span class="data">{{successData.amount}} {{successData.currency}} </span>
            </li>
            <!-- 购买通行证 -->
            <template v-if="successData.g_type == 2" >
              <li class="buy_pass">
                <span class="listTitle">{{ passText[lang] }}</span>
                <span v-if="lang !== 2" class="data">{{successData.first_month_day}}-{{successData.last_month_day}}(GMT+3)</span>
                <span v-else class="data">{{successData.first_month_day}}-{{successData.last_month_day}}(GMT+3)</span>
             </li>
            </template>
            <!-- 充值类型：pay_type (rwchargeType) 0-原先金币和通行证 1-礼包购买 2-礼包内充值 -->
            <template v-else>
                <!-- 购买金币 -->
                <li v-if="rwchargeType !== 1" class="buy_coins">
                    <span class="listTitle">{{ purchaseText[lang] }}</span>
                    <span class="data">{{successData.coins}} {{ coinsText[lang] }}</span>
                </li>
                <!-- 购买礼包 -->
                <li v-else>               
                    <span class="listTitle">{{ rwchargeTypeTexts[rwchargeType][lang] }}</span>
                    <span class="data" v-if="rwchargeType !== 1">{{successData.amount + '  ' + successData.currency}}</span>
                    <div class="data buy_pack" v-else-if="rwchargeType=== 1 && Number(successData.valid_day)<=1">{{ $t('m.giftandday', {m: successData.objective + ` USD `, n: successData.valid_day}) }}</div>
                    <div class="data buy_pack" v-else-if="rwchargeType=== 1 && Number(successData.valid_day)>1">{{ $t('m.giftandday1', {m: successData.objective + ` USD `, n: successData.valid_day}) }}</div>
                </li>
            </template>
            <!-- 购买时间 -->
            <li class="buy_time">
                <span class="listTitle">{{ purchasetimesText[lang] }}</span>
                <span class="data">{{pay_time}}</span>
            </li>
            <!-- 订单号 -->
            <li class="order_number">
                <span class="listTitle">{{ ordernumberText[lang] }}</span>
                <span class="data">{{successData.pay_code}}</span>
            </li>
        </ul>
        <span class="btn" @click="returnPages()">{{ doneText[lang] }}</span>
        <div class="btm_part" v-if="rwchargeType === 1">{{ $t('m.delayinOffertips') }}</div>
    </div>
</template>

<script>  
    import {timeConvert} from "@/assets/js/utils";
    export default {
        name: "Success",
        data() {
            return {
                successData: this.$route.query,  //路由参数
                rwchargeType: Number(this.$route.query.pay_type) || 0,  //# pay_type 0-原先金币和通行证 1-礼包购买 2-礼包内充值
                lang: Number(this.$route.query.lang) || 1,
                daysText: {
                    1: 'day',
                    2: 'يوم',                    
                    3: 'günler' ,
                },
                daysText2: {
                    1: 'days',
                    2: 'يوم',                    
                    3: 'günler' ,
                },
                usdTexts: {
                    1: 'USD',
                    2: 'USD',
                    3: 'USD',
                },
                offerTexts: {
                    1: 'offer',
                    2: 'عرض',
                    3: 'teklif'
                },
                successfulText: {
                    1: 'Recharge successfully',
                    2: 'تم الشحن بنجاح',
                    3: 'Şarj etmek başarılı'
                },
                doneText: {
                    1: 'Done',
                    2: 'تم',
                    3: 'Tamamlamak'
                },
                paymentText: {
                    1: 'Payment amount',
                    2: 'مبلغ الدفع', 
                    3: 'Ödeme miktarı'
                },
                passText: {
                    // 1: 'Luxury Pass',
                    // 2: 'الباس الخافر',
                    // 3: 'Lüks Pass'
                    1: 'Purchase Luxury Pass',
                    2: 'شراء الباس الفاخر',
                    3: 'Lüks Pass Satın Almak '
                },
                purchaseText: {
                    1: 'Purchase coins',
                    2: 'الكوينزات',
                    3: 'Jeton satın almak'
                },
                purchasegiftText: {
                    1: 'Purchase price',
                    2: 'سعر الشراء',
                    3: 'Sarın alma fiyatı',
                },
                coinsText: {
                    1: 'coins',
                    2: 'كوينز',
                    3: 'jeton'
                },
                purchasetimesText: {
                    1: 'Payment time',
                    2: 'وقت الدفع',
                    3: 'Ödeme zamanı'
                },
                ordernumberText: {
                    1: 'Order number',
                    2: 'رقم الطلب',
                    3: 'Sipariş numarası'
                },
                //# pay_type (rwchargeType) 0-原先金币和通行证 1-礼包购买 2-礼包内充值
                rwchargeTypeTexts: {
                    0: {
                        1:'coins',  
                        2:' كوينز',
                        3:' jeton'
                    },
                    1: {
                        1:'Offer name',  
                        2:'اسم العرض',   // 'اسم ا+-لعرض',
                        3:'Teklif adı'
                    }, //this.$t('m.name')
                    2: {
                        1:'coins',  
                        2:' كوينز',
                        3:' jeton'
                    }, //this.$t('m.coins')
                },
                params: {},
                routeobj: {
                    1: {
                        path: '/',
                        langType: 'zh-EN'
                    },
                    2: {
                        path: '/ar',
                        langType: 'zh-ER'
                    },
                    3: {
                        path: '/tr',
                        langType: 'zh-TR'
                    }
                }
            }
        },
        computed: {
          pay_time() {
              let pay_time = this.$route.query.pay_time
              console.log(pay_time, '.................支付时间')
              return pay_time ? timeConvert(pay_time): ''
          },
          lang() {
              let lang = this.$route.query.lang
              return lang ? parseInt(lang.toString()) : 1
          },
          dir() {
              return this.lang !== 2 ? 'ltr': 'rtl'
          }
        },
        created() {
            console.log(JSON.parse(JSON.stringify(this.$route.query)),'.....支付成功页面传进来的参数')
            console.log(this.lang, '................当前语言')
            ////console.log(this.rwchargeType, '...............rwchargeType')
            ////console.log(this.rwchargeTypeTexts[this.rwchargeType][this.lang])
            this.params = JSON.parse(JSON.stringify(this.$route.query))
            this.seti18nLang()
        },
        methods: {
            getQueryVariable(variable) {
                var query = window.location.search.substring(1);
                var vars = query.split("&");
                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split("=");
                    if (pair[0] == variable) { return pair[1]; }
                }
                return (false);
            },
            /**
             * @param 设置i18n语言
             * zh-EN  zh-ER  zh-TR
            */
            seti18nLang() {
                this.$i18n.locale = this.routeobj[this.lang].langType
                sessionStorage.setItem('lang', this.lang)
                //////console.log(' -EN ..........当前语言',    this.$i18n.locale, this.lang)
            },
            returnPages() {
                let jumpPath = this.routeobj[this.lang].path
                console.log(jumpPath, '.............回首页跳转路径')
                console.log(location.href, '.............location.href')
                console.log('当前页面url。。。。。。。。', location.href.split('?'))
                console.log('回首页路由参数..............', JSON.parse(JSON.stringify(this.params)))
               
                let _lang = this.lang, _currency = this.$route.query.currency
                let interceptUrl = location.href.split('?')[0]
                let newjumpUrl = interceptUrl.includes('#') ? interceptUrl.split('#')[0] : interceptUrl
                let pageUrl = newjumpUrl + '#' + jumpPath + `?lang=${_lang}&currency=${_currency}`
                console.log(newjumpUrl, '.............newjumpUrl')
                console.log(pageUrl, '................跳转的pageUrl')
                window.location.href = pageUrl
                // this.$router.push({path: jumpPath, query: {
                //     lang: _lang,
                //     currency: _currency
                // }})
            },
            // timeConvert(timestamp, num) {
            //     return timeConvert(timestamp, num)
            // }
        }
    }
</script>

<style scoped lang="scss">
    .success {
        background: #fff;
        /*position: fixed;*/
        /*top: 0;*/
        /*bottom: 0;*/
        /*left: 0;*/
        /*right: 0;*/
        overflow: hidden;
        color: #333;
        font-size: 15px;

        img {
            width: 25vw;
            display: block;
            margin: auto;
            margin-top: 15vw;
            padding-bottom: 2vw;
        }

        ul {
            margin-top: 10vw;

            li {
                width: 100vw;
                border-bottom: 0.5px solid #dbdbdb;
                text-align: left;
                padding: 4vw 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {
                    display: inline-block;
                }

                .listTitle {
                    margin-left: 4vw;
                    margin-right: 4vw;
                }

                .data {
                    font-size: 14px;
                    color: #6d6f73;
                    font-weight: initial;
                    margin-right: 4vw;
                    margin-left: 4vw;
                }
            }

            li:nth-last-child(0) {
                border: none;
            }
        }
        .btn {
            color: #fff;
            background: #73d13d;
            font-size: 16px;
            width: 45vw;
            display: block;
            margin: auto;
            margin-top: 10vw;
            padding: 3vw 0;
            border-radius: 8vw;
        }
        .btm_part {
            padding: 0 51px;
            height: 32px;
            font-size: 14px;
            font-weight: 500;
            color: #FF4D4F;
            line-height: 16px;
            position: fixed;
            bottom: 40px;
        }
    }
</style>