<template>
  <div :class="['commonProblem', lang === 2 ? 'rtl text_r' : 'text_l']">
    <span v-for="(i,k) in $t('m.commonproblems')" :key="k">
      <b>{{ i.title }}</b><br>
      <p>{{ i.subheading }}</p>
    </span>
    <!-- <span>
      <b>1.Where to check my coins after recharge?</b><br>
      <p>After recharge, you can open Whisper APP, and check your coins in "Me" - "Recharge Coins" page</p>
    </span>
    <span>
      <b>2. What if I didn't receive coins after recharge done?</b><br>
      <p>You can contact WhatsApp: +201112642082, and provide information: 1 recharge amount. 2 recharge date and time 3 screen shot of recharge bill. We shall verify the order and send you coins after that.</p>
    </span>
    <span>
      <b>3. What if I recharged for wrong ID?</b><br>
      <p>Sorry, you can't refund after any recharge, but you can contact the owner of the ID that you recharged for, and negotiate with him to pay you the amount.</p>
    </span> -->
  </div>
</template>

<script>

export default {
  name: 'commonProblem',
  data () {
    return {
      lang: Number(sessionStorage.getItem('lang'))
    }
  },
  created(){    
    console.log('语言.........', this.lang)
  },
  methods: {
  }
}
</script>

<style lang="scss">
.text_l {
  text-align: left;
}
.text_r {
  text-align: right;
}
.commonProblem {
  span {
    display: block;
    width: 90vw;
    margin: auto;
    margin-top: 10vw;
    font-size: 16px;
    color: #333;
    p {
      color: #6d6f73;
      font-size: 13px;
    }
  }
}
</style>