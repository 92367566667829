import {CKO_PUBLIC_KEY} from "@/assets/js/config";

export {
    baseRequest,
    cardPaymentMethod
}

// 指定google api版本
const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
};

// 付款令牌
const tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
        'gateway': 'checkoutltd',
        'gatewayMerchantId': CKO_PUBLIC_KEY
    }
};

// 指定支持的支付卡网络
const allowedCardNetworks = ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "MIR", "VISA"];
const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

// 允许的付款方式
const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks: allowedCardNetworks
    }
};

const cardPaymentMethod = Object.assign(
    {tokenizationSpecification: tokenizationSpecification},
    baseCardPaymentMethod
);