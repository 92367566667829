<template>
    <div class="Recharge">
        <!-- 头部 -->
        <div class="topLogo">
            <img class="logo" src="../assets/imgs/logo.png" alt="" />
            <span class="text">
                <img src="../assets/imgs/whisperText.png" alt="" />
                <span>Sohbet ve Parti</span>
            </span>
            <!-- <span class="toNobleShow" @click.prevent="toNoble">
                <img src="../assets/imgs/gLogo.png" alt="" />
                <span>
                    Aristokrasi
                    <van-icon name="arrow" />
                </span>
            </span> -->
        </div>
        <!-- 语言选择 -->
        <div class="top">
            <span class="title">Hesap şarj etmek</span>
            <div class="switchLang" @click="language()">
                <img src="../assets/imgs/earth.png" alt="" />
                <span>{{ languages }}</span>
                <img :src="langImg" alt="" />
            </div>
        </div>
         <!-- bp控制当前页面是否可以滑动 -->
        <div class="bottomPage" :class="bp">
            <!-- 未输入用户id -->
            <div class="setID" :style="setId" v-if="userList.length === 0">
                <input class="input" type="text" v-model="id" placeholder="Whisper ID’yi Girin" />
                <span class="Textbtn" @click="verify(1, 0)">Onayla</span>
            </div>
            <div class="setID" :style="setId" v-else>
                <input class="input i2" type="text" v-model="id" placeholder="Whisper ID’yi Girin" />
                <van-icon name="arrow-down" :class="icons" @click="openList()" />
                <span class="Textbtn" @click="verify(1, 0)">Onayla</span>
            </div>

            <div class="userListHeight" :style="setId" :class="userListHeight">
                <ul>
                    <li v-for="(item, index) in userList" :key="index">
                        <div @click="ListClick(item)">
                            <span class="head"><img :src="item.head" alt="" /></span>
                            <span class="nameOrid">
                                <span>
                                    <span class="name">{{ item.name }}</span>
                                    <span class="lv">Lv.{{ item.normal_level }}</span>
                                </span>
                                <span class="wid">Whisper ID : {{ item.rid }}</span>
                            </span>
                            <span :class="['status', checkColors[utils.checkTokenauth(item)]]"> {{ utils.checkTokenauth(item) ? $t('m.verifiStaus[1]') : $t('m.verifiStaus[0]') }}</span>
                        </div>
                        <span class="ico">
                            <van-icon name="clear" color="#c5c5c5" @click="deleteList(item)" />
                        </span>
                    </li>
                </ul>
            </div>

            <div class="setID" :style="getId">
                <span class="head"><img :src="userInfo.head" alt="" /></span>
                <span class="nameOrid">
                    <span>
                        <span class="name">{{ userInfo.name }}</span>
                        <span class="lv">Lv.{{ userInfo.normal_level }}</span>
                    </span>
                    <span class="wid">Whiseper ID : {{ userInfo.rid }}</span>
                </span>
                <span :class="['status', checkColors[utils.checkTokenauth(userInfo)]]"> {{ utils.checkTokenauth(userInfo) ? $t('m.verifiStaus[1]') : $t('m.verifiStaus[0]') }}</span>
            </div>

            <div class="explain" :style="setId" @click="show2 = true">
                Whisper ID’yi nedir >
            </div>

            <checkIdentity v-if="utils.checkTokenauth(userInfo) === 0" @checkLogin="verify"></checkIdentity>

            <div class="explain2" :style="getId" @click="returnSetId()">
                Başka bir Whisper ID'yi için şarj edin
            </div>

            <!-- 支付列表 -->
            <div class="payList">
                <div class="title">
                    <span class="title-left">Ödeme yöntemleri</span>
                    <span class="title-right" @click="show_currency_popup">Para Birimi Seçmek ></span>
                </div>
                
                 <!-- 选择支付方式
                @handlerRecharge  普通金币支付处理
                -->
                <choosePaymethod 
                v-if="isFlag && isFlag1"  
                ref="choosePayway"     
                :userInfo=userInfo   
                :lang="lang" 
                :payList="pay_list" 
                :coinsandOffersList="coinsandOffersList" 
                :czList="czList" 
                :activeNamesList="activeNames" 
                :giftactiveName = "giftactiveName"
                :giftList = "giftList"
                :currency="currency"
                :key="userInfo.user_id"
                @loginCheck="verify"
                @showRechargePop="handlergiftRecharge"
                @handlerRecharge="recharge"
                @handlerChoosefn="choosePaymethodsfn"          
                @sendcheckCode="sendLogincode">
                </choosePaymethod>
                
                <!-- 支付方式及每个类目下的子集 -->

                <!-- <van-collapse v-model="activeNames" accordion>
                    <van-collapse-item v-for="(item, index) in pay_list" :key="index" :name="index">
                        <template #title>
                            <div class="titles">
                                <img class="payImg" :src="item.payImg" alt="" />
                                <span class="payName">{{ item.name }}</span>
                            </div>
                        </template>
                        <div class="recNumList">
                            <ul>
                                <li v-for="(value, index) in czList" :key="index">
                                    <span class="jinbi">
                                        <template v-if="czList[index].good_id === 'good_19'">
                                            <img class="gc" src="../assets/imgs/icon_task_nor.png" alt="" />
                                        </template>
                                        <template v-else>
                                            <img class="gc" src="../assets/imgs/gc.png" alt="" />
                                        </template>
                                        <span class="gcnum">
                                            {{ value.origin_coins }}
                                            <span class="addCoin" v-if="value.num > 0">+{{ value.num }}</span>
                                        </span>
                                    </span>
                                    <span class="recBtn" @click="recharge(value, item.name)">
                                        {{
                                            currency + " " + value[currency.toLowerCase()]
                                        }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </van-collapse-item>
                </van-collapse> -->
            </div>

            <div class="bottom">
                <span @click="faq()">
                    <img src="../assets/imgs/wen.png" alt="" />
                    <span>SSS</span>
                </span>
            </div>

            <!-- 说明 -->
            <div class="shuoming">
                <span class="content" @click="about()">Hakkımızda ></span>
                <span>Müşteri Hizmetleri İletişim:</span>
                <!-- <span class="wa"><img src="../assets/imgs/wa.png" alt="">WhatsApp：+2010321274</span> -->
                <span class="email">
                    <img src="../assets/imgs/xin.png" alt="" />
                    Email：whisperfeedback@gmail.com
                </span>
                <p>
                    1318-19 numaralı oda, Hollywood Plaza'nın 13. katında, Kowloon'da Mongkok'ta bulunan 610 Nathan Road adresinde yer almaktadır. (Hong Kong)
                </p>
                <p>
                    Whisper, kullanıcılara grup sesli sohbet odaları ve diğer eğlence etkinlikleri için en iyi deneyimi sunan bir sosyal uygulamadır. Bu web sitesi, Whisper uygulaması için sanal jeton şarjı yapmanızı sağlar.
                </p>
                <p>
                    Sanal jeton yükleme fiyatı, politikalarımıza göre güncellenebilir, lütfen unutmayın.
                </p>
            </div>

            <!-- 语言选择弹出层 -->
            <van-popup v-model="show">
                <div class="langList">
                    <span class="n n1" @click="switchLang(1, 'English')">
                        <span>English</span>
                    </span>
                    <span class="n n2" @click="switchLang(2, 'اللغة العربية')">
                        <span>اللغة العربية</span>
                    </span>
                    <span class="n n3" @click="switchLang(3, 'Turkish')">
                        <span>Turkish</span>
                    </span>
                </div>
            </van-popup>
        </div>

        <!-- 了解whisper弹出层 -->
        <van-popup class="show2" v-model="show2" closeable close-icon-position="bottom" close-icon="clear">
            <div class="liaojieImg">
                <img src="../assets/imgs/whisper.png" alt="" />
            </div>
        </van-popup>

        <!--    <van-popup v-model="payShow">-->
        <!--      <div class="payPage">-->
        <!--        <iframe src="./static/pay.html" ref="iframe" id="iframes" name="payIframe" frameborder="0"></iframe>-->
        <!--        <input type="text" :value="payNum" id="aa" style="position:absolute;opacity:0">-->
        <!--      </div>-->
        <!--    </van-popup>-->

        <!-- 构建订单加载 -->
        <VanLoading size="40px" v-show="loading" class="loading" color="#ffffff" type="spinner" vertical>
            <div class="loading-text"></div>
        </VanLoading>

        <van-popup class="currency-list" v-model="showCurrencyList" round>
            <div class="title">Para Birimi Seçmek</div>
            <ul>
                <li v-for="(value, index) in currencyList" :key="index" class="currency-node"
                    @click="choose_currency(value)">
                    {{ value }}
                </li>
            </ul>
        </van-popup>

        <!-- 点击普通金币充值弹窗 -->
        <van-popup v-model="payShow" style="background:none">
            <div class="payPage">
                <div class="content">
                    <div class="payText">
                        <!-- <span class="title" v-if=" payData.good_id === 'good_19'"> The Lüks Pass will only be available in this month after purchasing, confirm to purchase Lüks Pass?</span> -->
                        <!-- <span class="title" v-if="payData.good_id === 'good_19'"> Confirm to purchase Lüks Pass? It will be
                            activated from {{ this.passStartDate }} to {{ this.passEndDate }} after purchase</span> -->

                        <span class="title" v-if="payData.good_id === 'good_19'">Lüks Geçiş satın almayı onaylamak istiyor musunuz? Satın alma işleminden sonra {{ this.passStartDate }} ile {{ this.passEndDate }} arasında etkinleştirilecektir.</span>
                        <span class="title" v-else> {{ payData.coins }} jeton satın almayı onaylıyor musunuz?</span>
                        <span class="payTip">{{ payNum }} {{ currency }} ödemeniz gerekiyor</span>                        
                    </div>
                    <div class="checkCard" v-if="payData.good_id != 'good_19'">
                        <van-checkbox v-model="checked" shape="square" checked-color="#000">
                            <template #icon="props">
                                <img class="img-icon"
                                    :src="props.checked ? require('../assets/imgs/check.png') : require('../assets/imgs/u_check.png')" />
                            </template>
                            <span class="checkText">Kupon kullan</span>
                        </van-checkbox>
                    </div>
                </div>
                <div class="buttom">
                    <span class="btn cancel" @click="payShow = false">İptal etmek</span>
                    <span class="btn confirm" @click="build_payment()">Onaylamak</span>
                </div>
            </div>
        </van-popup>

        <!-- <van-loading class="loading2" v-if="loading == true" type="spinner" color="#1989fa" /> -->

        <!-- 充值特惠日弹窗 -->
        <czspecialOffer v-if="ifRechargespecialday" :lang="lang" :showofferpop="ifRechargespecialday" :optType="1" @closePop="closeofferPop" ></czspecialOffer>    

        
        <!-- 填写验证码弹窗 -->
        <fillinCheckcode v-if="ifShowcheckCodespop" 
        :optType = "optType"
        :checkCodeshow="ifShowcheckCodespop" 
        :userInfo="userInfo" 
        :checkcodeType="checkcodeType"
        @sendAgainback="verify"
        @loginSuccessback="loginSuccesssaveUserdata"
        @loginSuccesslistclickback="listClickcallback"
        @closecheckcodePop="closecheckcodePop"></fillinCheckcode>

        <!-- 购买礼包 、 充值购买礼包下的金币弹窗 -->
        <rechargePop v-if="ifShowrechargePop" 
        :lang="lang"
        :curreny="currency"
        :giftpayShow="ifShowrechargePop" 
        :payData="giftPaydata" 
        :rechargeType="rechargeType" 
        @handlerBuildPayment="gifpackBuildPayment"
        @closePop="closerechargePop"></rechargePop>

    </div>
</template>
  
<script>
import mixin1 from "../mixins/mixin1";

export default {
    name: "RechargeTR",
    mixins: [mixin1],
    data() {
        return {            
            languages: "Turkish", //默认显示的语言            
            lang: 3,
            // activeNames: [],
            // id: "",
            // pay_list: [
            //     // {
            //     //   payImg: require('../assets/imgs/pay1.png'),
            //     //   name: 'Fawry',
            //     // },
            //     {
            //         payImg: require("../assets/imgs/Visa.png"),
            //         name: "Visa",
            //     },
            //     {
            //         payImg: require("../assets/imgs/Master_Card.png"),
            //         name: "Master Card",
            //     },
            //     {
            //         payImg: require("../assets/imgs/Mada_Pay.png"),
            //         name: "Mada Pay",
            //     },
            //     {
            //         payImg: require("../assets/imgs/Apple_Pay.png"),
            //         name: "Apple Pay",
            //     },
            //     // {
            //     //   payImg: require("../assets/imgs/google-pay-mark_800.svg"),
            //     //   name: "Google Pay",
            //     // },
            //     {
            //         payImg: require("../assets/imgs/American_Express.png"),
            //         name: "American Express",
            //     },
            //     {
            //         payImg: require("../assets/imgs/Troy.png"),
            //         name: "Troy",
            //     },
            //     {
            //         payImg: require("../assets/imgs/DinersClub_International.png"),
            //         name: "DinersClub International",
            //     },
            // ],
            // show: false, //控制语言选择弹出层
            // show2: false,//了解whisper弹出层
            // langImg: require("../assets/imgs/down.png"), //语言选择折叠箭头
            // userInfo: {},
            // setId: "",
            // getId: "display:none", //隐藏input框
            // userList: [],
            // icons: "",
            // userListHeight: "",
            // bp: "",
            // czList: [],
            // payShow: false,
            // payNum: "0",
            // token: "",
            // good_id: "",
            // pay_method: "",
            // loading: false,
            // lang: 1,
            // showCurrencyList: false,
            // currency: "USD",
            // currencyList: ["USD", "SAR", "AED", "QAR", "KWD", "OMR", "BHD", "JOD"],
            // g_type: 1,
            // payShow: false,
            // payData: {},
            // checked: false,
            // passStartDate: '',
            // passEndDate: '',
        };
    },
    computed: {},
    // watch: {
    //     show(val) {
    //         if (val == false) {
    //             this.langImg = require("../assets/imgs/down.png"); 
    //         }
    //     },
    //     userList(list) {
    //         if (list.length === 0) {
    //             this.icons = "";
    //             this.userListHeight = "";
    //         }
    //     },
    //     payShow(val) {
    //         if (val == false) {
    //             this.checked = false
    //         }
    //     }
    // },
    mounted() {
        // sendPageView();
        this.globalData.lang = this.lang
        sessionStorage.setItem('lang', this.lang)
    },
    created() {
        let idList = sessionStorage.getItem("idList");
        if (idList != "" && idList != null) {
            this.userList = JSON.parse(idList);
        }
        // this.getGCList();
    },
    methods: {
        // show_currency_popup() {
        //     this.showCurrencyList = true;
        // },
        // choose_currency(currency) {
        //     this.currency = currency;
        //     this.showCurrencyList = false;
        // },

        
        /**
         * 支付列表 -  点击支付
         * data       当前支付的value数据
         * pay_method 支付方式
        */
        // recharge(data, pay_method) {
        //     //console.log(JSON.parse(JSON.stringify(data)), pay_method, "点击的弹窗");

        //     this.payNum = data[this.currency.toLowerCase()];
        //     this.good_id = data.good_id;
        //     this.pay_method = pay_method;
        //     this.g_type = data.g_type;
        //     if (this.id == "") {
        //         this.$toast("Lütfen önce kimlik numarasını girin.");
        //     } else {
        //         this.payData = data
        //         this.payShow = true

        //         // this.$toast('Coming soon')
        //         // this.$dialog
        //         //   .confirm({
        //         //     // title: "Confirm to purchase " + data.coins + "  coins?",
        //         //     title:
        //         //       data.good_id === "good_19"
        //         //         ? "The Lüks Pass will only be available in this month after purchasing, confirm to purchase Lüks Pass?"
        //         //         : "Confirm to purchase " + data.coins + "  coins?",
        //         //     message: "You need to pay " + this.payNum + "  " + this.currency,
        //         //     confirmButtonText: "Confirm", //改变确认按钮上显示的文字
        //         //     cancelButtonText: "Cancel", //改变取消按钮上显示的文字
        //         //     confirmButtonColor: "#333333",
        //         //     cancelButtonColor: "#959AA6",
        //         //   })
        //         //   .then(() => {
        //         //     // on confirm
        //         //     // this.payShow = true
        //         //     this.build_payment();
        //         //     // this.$router.push('/credit')

        //         //     // this.$toast('Coming soon')
        //         //   })
        //         //   .catch(() => {
        //         //     // on cancel
        //         //   });
        //     }
        // },

        //充值弹窗 - 确认支付 
        // build_payment() {
        //     this.loading = true;
        //     let data = {
        //         uid: this.userInfo.user_id,
        //         good_id: this.good_id,
        //         amount: this.payNum,
        //         pay_method: this.pay_method,
        //         currency: this.currency,
        //         lang: this.lang,
        //         session_id: window._session_id || "",
        //         g_type: this.g_type,
        //     };
        //     //console.log(data, "确认支付 data参数");
        //     let url = BASE_URL + "/pay/checkout/payment/build";
        //     axios
        //         .post(url, data, {
        //             httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        //         })
        //         .then((res) => {
        //             this.loading = false;
        //             if (res.data.code == 0) {
        //                 //console.log(res.data.data, "res", "构建支付回参");
        //                 res.data.data.desc = 'Lüks Pass'
        //                 let coins = res.data.data.coins;
        //                 let code = res.data.data.code;
        //                 let amount = res.data.data.amount.toString();
        //                 let payment_id = res.data.data.payment_id.toString();
        //                 let currency = res.data.data.currency;
        //                 let desc = res.data.data.desc;
        //                 let first_month_day = res.data.data.first_month_day;
        //                 let last_month_day = res.data.data.last_month_day;
        //                 let router_name;
        //                 let g_type = res.data.data.g_type
        //                 switch (this.pay_method) {
        //                     case "Visa": {
        //                         router_name = "Credit";
        //                         break;
        //                     }
        //                     case "Mada Pay": {
        //                         router_name = "Credit";
        //                         break;
        //                     }
        //                     case "Master Card": {
        //                         router_name = "Credit";
        //                         break;
        //                     }
        //                     case "American Express": {
        //                         router_name = "Credit";
        //                         break;
        //                     }
        //                     case "Troy": {
        //                         router_name = "Credit";
        //                         break;
        //                     }
        //                     case "DinersClub International": {
        //                         router_name = "Credit";
        //                         break;
        //                     }
        //                     case "Google Pay": {
        //                         router_name = "google_pay";
        //                         break;
        //                     }
        //                     case "Apple Pay": {
        //                         router_name = "apple_pay";
        //                         break;
        //                     }
        //                     case "Fawry": {
        //                         router_name = "Fawry";
        //                         break;
        //                     }
        //                 }
        //                 let query = {
        //                     coins,
        //                     code,
        //                     amount,
        //                     payment_id,
        //                     currency,
        //                     desc,
        //                     first_month_day,
        //                     last_month_day,
        //                     g_type,
        //                     pay_method: this.pay_method,
        //                     lang: this.lang,
        //                     good_id: this.good_id,
        //                 }
        //                 if (this.checked == true) {
        //                     if (this.userInfo.promotion_day == 1) { 
        //                         //console.log('//无法使用优惠券，今天是充值优惠日')
        //                         this.$toast("Kupon kullanılamıyor, şu anda Şarj Teklifi Günü.")
        //                     } else if (this.payData.usd > 900) { 
        //                         //console.log('//无法使用优惠券，充值超过900美金将无法使用优惠券')
        //                         this.$toast("Kupon kullanılamıyor, 900 USD üzeri şarj işlemlerinde kupon geçersiz.")
        //                     } else {
        //                         let datas = {
        //                             uid: this.userInfo.user_id,
        //                             pay_type: 1,
        //                             payment_id: payment_id,
        //                             lang: this.lang,
        //                         }
        //                         this.payShow = false
        //                         setTimeout(() => {
        //                             this.$router.push({ name: 'rechargeCoupon', params: { codeData: datas, payData: query, routerName: router_name } })
        //                         });
        //                     }

        //                 } else {
        //                     if (res.data.data.Pass === 1) { //购买失败，您在此期间已经购买了通行证
        //                         //console.log('//购买失败，您在此期间已经购买了通行证')
        //                         this.$toast("Satın alma işlemi başarısız oldu, bu sürede zaten lüks Pass satın aldınız");
        //                     } else {
        //                         this.payShow = false
        //                         this.$router.push({
        //                             name: router_name,
        //                             query: {
        //                                 coins,
        //                                 code,
        //                                 amount,
        //                                 payment_id,
        //                                 currency,
        //                                 desc,
        //                                 first_month_day,
        //                                 last_month_day,
        //                                 g_type,
        //                                 pay_method: this.pay_method,
        //                                 lang: this.lang,
        //                                 good_id: this.good_id,
        //                             },
        //                         });
        //                     }
        //                 }
        //             } else if (res.data.code == 887) { //对不起，你需要先达到用户等级30
        //                 //console.log('//对不起，你需要先达到用户等级30')
        //                 this.$toast("Üzgünüz, önce Kullanıcı Seviyesi 30'a ulaşman gerekiyor");
        //             }
        //         });

        // },

        //充值金额列表
        // getGCList() {
        //     let url = BASE_URL + "/pay/checkout/goods/list";
        //     axios.get(url).then((res) => {
        //         if (res.status === 200) {
        //             if (res.data.code === 0) {
        //                 let { first_month_day, last_month_day, list, now_time } = res.data.data
        //                 this.globalData.serverTime = now_time
        //                 this.globalData.ifRechargespecialday = promotion_day === 1 ? true : false
        //                 this.ifRechargespecialday = promotion_day === 1 ? true : false
        //                 this.passStartDate = first_month_day
        //                 this.passEndDate = last_month_day
        //                 for (let i = 0; i < list.length; i++) {
        //                     let num = list[i].coins - list[i].origin_coins;
        //                     list[i].num = num;
        //                 }
        //                 list[0].desc = 'Lüks Pass'
        //                 list[0].origin_coins = 'Lüks Pass';  //修改豪华通行证文案
        //                 this.czList = list;
        //                 //console.log(JSON.parse(JSON.stringify(this.czList)), "this.czList");
        //             }
        //         }
        //     });
        // },

        // //跳转说明
        // about() {
        //     this.$router.push("/about");
        // },
        //跳转贵族页面
        toNoble() {
            this.$router.push("/noble");
        },
        //返回搜索id
        // returnSetId() {
        //     this.setId = "";
        //     this.getId = "display:none";
        //     // location.reload();
        // },
        //删除搜索列表数据
        // deleteList(data) {
        //     let list = this.userList;
        //     for (let i = 0; i < list.length; i++) {
        //         if (data.rid == list[i].rid) {
        //             list.splice(i, 1);
        //         }
        //     }
        //     sessionStorage.setItem("idList", JSON.stringify(list), 7);
        // },
        //选择搜索列表数据
        // ListClick(data) {
        //     // //console.log(data)
        //     this.userInfo = data;
        //     this.id = data.rid;
        //     this.setId = "display:none";
        //     this.getId = "";
        //     this.icons = "";
        //     this.userListHeight = "";
        //     this.bp = "";
        // },
        // openList() {
        //     if (this.icons == "") {
        //         this.icons = "icons";
        //         this.userListHeight = "list";
        //         this.bp = "bp";
        //     } else {
        //         this.icons = "";
        //         this.userListHeight = "";
        //         this.bp = "";
        //     }
        // },
        //点击搜索
        // verify() {
        //     // this.removeCookie('idList')
        //     let idList = sessionStorage.getItem("idList");

        //     if (this.id == "") {
        //         this.$toast("Lütfen önce kimlik numarasını girin.");
        //     } else {
        //         let url = BASE_URL + "/pay/recharge/get_user_info?id=" + this.id+`&lang=${this.lang}`;
        //         axios.get(url).then((res) => {
        //             if (res.status === 200) {
        //                 if (res.data.code === 0) {
        //                     this.setId = "display:none";
        //                     this.getId = "";
        //                     this.userInfo = res.data.data;

        //                     //添加用户搜索id历史保存cookie
        //                     let userData = res.data.data;
        //                     let num = 0;

        //                     //判断cookie是否有数据
        //                     if (idList == "" || idList == null) {
        //                         let list = [];
        //                         list.push(userData);
        //                         this.userList = list;
        //                         sessionStorage.setItem("idList", JSON.stringify(list), 7);
        //                     } else {
        //                         let list = JSON.parse(idList);
        //                         let idlist = JSON.parse(idList);
        //                         //如果有数据的话就判断是否有相同数据
        //                         for (let i = 0; i < idlist.length; i++) {
        //                             if (idlist[i].rid == userData.rid) {
        //                                 num++;
        //                             }
        //                         }

        //                         //没有相同数据就把该数据添加到cookie
        //                         if (num === 0) {
        //                             list.push(userData);
        //                             this.userList = list;
        //                             // this.removeCookie('idList')
        //                             sessionStorage.setItem("idList", JSON.stringify(list), 7);
        //                         }
        //                     }
        //                 } else {
        //                     this.$toast("ID geçerli değil");
        //                 }
        //             }
        //         });
        //         }
        // },

        //保存cookie
        // saveCookie(cookieName, cookieValue, cookieDates) {
        //     var d = new Date();
        //     d.setDate(d.getDate() + cookieDates);
        //     document.cookie =
        //         cookieName + "=" + cookieValue + ";expires=" + d.toGMTString();
        // },
        // //获取cookie
        // getCookie(cookieName) {
        //     var cookieStr = unescape(document.cookie);
        //     var arr = cookieStr.split("; ");
        //     var cookieValue = "";
        //     for (var i = 0; i < arr.length; i++) {
        //         var temp = arr[i].split("=");
        //         if (temp[0] == cookieName) {
        //             cookieValue = temp[1];
        //             break;
        //         }
        //     }
        //     return cookieValue;
        // },
        // //删除cookie
        // removeCookie(cookieName) {
        //     document.cookie =
        //         encodeURIComponent(cookieName) + "=; expires =" + new Date();
        // },
        // //语言切换
        // language() {
        //     this.show = true;
        //     let langClickImg = {
        //         down: require("../assets/imgs/down.png"),
        //         up: require("../assets/imgs/up.png"),
        //     };
        //     this.langImg = langClickImg.up;
        // },
        // switchLang(index, lang) {
        //     //console.log(index, lang)

        //     let routeobj = {
        //         1: '/',
        //         2: '/ar',
        //         3: '/tr'
        //     }

        //     this.languages = lang
        //     // switch (index) {
        //     //   case 1:
        //     //     // this.lang = 1
        //     //     this.$router.push('/')
        //     //     break
        //     //   case 2:
        //     //     // this.lang = 2
        //     //     this.$router.push("/ar");
        //     //     break
        //     //   case 3:
        //     //     // this.lang = 3;  
        //     //     this.$router.push("/tr");
        //     //     break;
        //     //   default:
        //     //     // this.lang = 1
        //     //     this.$router.push('/')
        //     //     break;
        //     // }
        //     this.lang = index;
        //     this.$router.push(routeobj[index])
        //     this.show = false
        // },
        faq() {
            this.$router.push("/common-problem-tr");
        },
    },
};
</script>
  
<style lang="scss" scoped>
@import '../assets/css/color.scss';
@import '../assets/css/rechargeCommon.scss';
// .loading {
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 121;
//     width: 100%;
//     height: 100%;
//     background: rgba(0, 0, 0, 0.3);
//     vertical-align: middle;
//     text-align: center;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .loading-text {
//     color: #ffffff;
// }

// .van-dialog {
//     .van-dialog__header {
//         font-weight: bold;
//         padding-left: 5px !important;
//         padding-right: 5px !important;
//     }

//     .van-dialog__footer--buttons {
//         .van-button {
//             font-weight: bold;
//         }
//     }
// }

// .Recharge {
//     background: #f8f8f8;
//     min-height: 100vh;
//     font-weight: bold;

//     .van-loading {
//         position: fixed;
//         color: #c8c9cc;
//         font-size: 0;
//         vertical-align: middle;
//         top: 0;
//         left: 0;
//         right: 0;
//     }

//     .topLogo {
//         width: 100%;
//         background: linear-gradient(270deg, #fb6c90 0%, #ff9b67 100%);
//         display: flex;
//         align-items: center;

//         .logo {
//             width: 17vw;
//             padding: 5vw 4vw;
//         }

//         .text {
//             img {
//                 height: 8vw;
//             }

//             span {
//                 display: block;
//                 color: #fff;
//                 font-size: 12px;
//                 text-align: left;
//             }
//         }

//         .toNobleShow {
//             color: #fff;
//             font-size: 16px;
//             display: flex;
//             align-items: center;
//             justify-content: flex-end;
//             width: 48vw;

//             img {
//                 width: 10vw;
//                 padding: 0 2vw;
//             }

//             span {
//                 display: flex;
//                 align-items: center;
//             }
//         }
//     }

//     .top {
//         background: #f8f8f8;
//         color: #333;
//         font-size: 16px;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;

//         img {
//             width: 5vw;
//             padding: 0 1vw;
//         }

//         .title {
//             line-height: 15vw;
//             padding: 0 3vw;
//         }

//         .switchLang {
//             display: inline-block;
//             display: flex;
//             align-items: center;
//             margin-right: 3vw;
//         }
//     }

//     .bottomPage {
//         .van-overlay {
//             height: 100%;
//             top: 0;
//             background-color: #0000004d;
//         }

//         .van-popup {
//             background: #fff;
//         }

//         .van-popup--center {
//             position: absolute;
//             top: 60vw;
//             left: 79vw;
//             border-radius: 1vw;

//             .langList {
//                 padding: 0 5vw;

//                 span {
//                     display: block;
//                     font-size: 15px;
//                     color: #333;
//                     font-weight: bold;
//                 }

//                 .n {
//                     width: 25vw;

//                     span {
//                         padding: 4vw 0;
//                     }
//                 }

//                 .n1 {
//                     span {
//                         border-bottom: 1px solid #dbdbdb;
//                     }
//                 }

//                 .n2 {
//                     span {
//                         border-bottom: 1px solid #dbdbdb;
//                     }
//                 }
//             }
//         }
//     }

//     .bp {
//         height: 76vh;
//         overflow: hidden;
//     }

//     .setID {
//         background: #fff;
//         border-bottom: 1px solid #dbdbdb;
//         display: flex;
//         align-items: center;

//         input {
//             border: none;
//             font-size: 16px;
//             width: 60vw;
//             margin: 5vw 3vw;
//         }

//         .van-icon {
//             margin-right: 5vw;
//             transform: rotate(0deg);
//             /* 水平镜像翻转 */
//             transition: transform 0.5s;
//             -webkit-transition: transform 0.5s;
//             /* Safari */
//         }

//         .icons {
//             margin-right: 5vw;
//             transform: rotate(180deg);
//             /* 水平镜像翻转 */
//             transition: transform 0.5s;
//             -webkit-transition: transform 0.5s;
//             /* Safari */
//         }

//         .i2 {
//             width: 50vw;
//         }

//         .Textbtn {
//             border: 1px solid #40a9ff;
//             border-radius: 2vw;
//             color: #40a9ff;
//             display: inline-block;
//             width: 25vw;
//             line-height: 8vw;
//         }

//         .head {
//             width: 15vw;
//             height: 15vw;
//             padding: 4vw 4vw;

//             img {
//                 width: 15vw;
//                 height: 15vw;
//                 border-radius: 50%;
//             }
//         }

//         span {
//             display: inline-block;
//         }

//         .nameOrid {
//             width: 40vw;
//             text-align: left;

//             span {
//                 display: flex;
//                 align-items: center;

//                 .name {
//                     display: inline-block;
//                     max-width: 70vw;
//                     overflow: hidden;
//                     text-overflow: ellipsis;
//                     white-space: nowrap;
//                     margin-right: 2vw;
//                 }

//                 .lv {
//                     background: #40a9ff;
//                     color: #fff;
//                     font-weight: bold;
//                     font-size: 12px;
//                     width: 13vw;
//                     border-radius: 3vw;
//                     text-align: center;
//                     line-height: 5vw;
//                     display: inline-block;
//                 }
//             }

//             .wid {
//                 color: #6d6f73;
//                 font-size: 12px;
//                 font-weight: 100;
//             }
//         }

//         input::-webkit-input-placeholder {
//             /* WebKit browsers */
//             color: #959aa6;
//             font-weight: bold;
//         }

//         input:-moz-placeholder {
//             /* Mozilla Firefox 4 to 18 */
//             color: #959aa6;
//             font-weight: bold;
//         }

//         input::-moz-placeholder {
//             /* Mozilla Firefox 19+ */
//             color: #959aa6;
//             font-weight: bold;
//         }

//         input:-ms-input-placeholder {
//             /* Internet Explorer 10+ */
//             color: #959aa6;
//             font-weight: bold;
//         }
//     }

//     .userListHeight {
//         ul {
//             height: 0px;
//             visibility: hidden;
//             transition: height 0.5s;
//             -webkit-transition: height 0.5s;

//             /* Safari */
//             li {
//                 background: #fff;
//                 display: flex;
//                 align-items: center;

//                 border-bottom: 1px solid #dbdbdb;

//                 div {
//                     display: flex;
//                     align-items: center;
//                 }

//                 .head {
//                     width: 15vw;
//                     height: 15vw;
//                     padding: 2vw 4vw;

//                     img {
//                         width: 15vw;
//                         height: 15vw;
//                         border-radius: 50%;
//                     }
//                 }

//                 span {
//                     display: inline-block;
//                 }

//                 .nameOrid {
//                     width: 40vw;
//                     text-align: left;

//                     span {
//                         display: flex;
//                         align-items: center;

//                         .name {
//                             display: inline-block;
//                             max-width: 65vw;
//                             overflow: hidden;
//                             white-space: nowrap;
//                             text-overflow: ellipsis;

//                             margin-right: 1vw;
//                         }

//                         .lv {
//                             background: #40a9ff;
//                             color: #fff;
//                             font-weight: bold;
//                             font-size: 12px;
//                             width: 13vw;
//                             border-radius: 3vw;
//                             text-align: center;
//                             line-height: 5vw;
//                             display: inline-block;
//                         }
//                     }

//                     .wid {
//                         color: #6d6f73;
//                         font-size: 12px;
//                         font-weight: 100;
//                         display: block;
//                     }
//                 }
//             }
//         }
//     }

//     .list {
//         background: #00000059;
//         position: fixed;
//         z-index: 100;
//         width: 100%;
//         top: 57.5vw;
//         bottom: 0;

//         ul {
//             visibility: visible;
//             height: 35vw;
//         }
//     }

//     .explain {
//         font-size: 14px;
//         color: #6d6f73;
//         background: #fff;
//         line-height: 8vw;
//         margin-top: 3vw;
//     }

//     .explain2 {
//         font-size: 14px;
//         color: #40a9ff;
//         background: #fff;
//         line-height: 8vw;
//         margin-top: 3vw;
//     }

//     .payList {
//         margin-top: 7vw;

//         .title {
//             display: flex;
//             justify-content: space-between;
//             flex-wrap: nowrap;

//             .title-left {
//                 display: block;
//                 color: #6d6f73;
//                 font-size: 13px;
//                 padding: 0 4vw;
//             }

//             .title-right {
//                 display: block;
//                 color: #40a9ff;
//                 font-size: 16px;
//                 padding: 0 4vw;
//             }
//         }

//         .van-cell {
//             align-items: center;
//         }

//         .van-collapse-item {
//             border-bottom: 1px solid #dbdbdb;
//             margin-top: 3vw;

//             .van-icon {
//                 font-size: 5vw;
//                 height: 12vw;
//                 line-height: 12vw;
//             }
//         }

//         .van-cell--clickable:active {
//             background: #fff;
//         }

//         .van-collapse-item__content {
//             padding: 0 16px;
//         }

//         .titles {
//             background: #fff;
//             display: flex;
//             align-items: center;

//             .payImg {
//                 height: 16vw;
//                 margin: 0 3vw 0 0;
//             }

//             .payName {
//                 font-size: 14px;
//                 color: #333;
//                 display: inline-block;
//                 width: 55vw;
//                 text-align: left;
//             }

//             .ico {
//                 width: 5vw;
//             }
//         }

//         .recNumList {
//             ul {
//                 li {
//                     display: flex;
//                     align-items: center;
//                     justify-content: space-between;
//                     padding: 3vw 0;
//                     border-bottom: 0.5px solid #dbdbdb;

//                     .jinbi {
//                         display: flex;
//                         align-items: center;
//                     }

//                     .gc {
//                         width: 6vw;
//                         margin-right: 1vw;
//                     }

//                     .gcnum {
//                         color: #333;
//                         font-size: 14px;

//                         .addCoin {
//                             color: #40a9ff;
//                             font-size: 13px;
//                         }
//                     }

//                     .recBtn {
//                         display: inline-block;
//                         border: 1px solid #333;
//                         border-radius: 1vw;
//                         padding: 1vw 0;
//                         width: 35vw;
//                         color: #333;
//                     }
//                 }

//                 li:last-child {
//                     border: none;
//                 }
//             }
//         }
//     }

//     .bottom {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         padding: 5vw;

//         img {
//             width: 5vw;
//             margin-right: 1vw;
//         }

//         span {
//             color: #6d6f73;
//             font-size: 13px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//         }
//     }

//     .shuoming {
//         padding-bottom: 10vw;

//         span {
//             display: block;
//             font-size: 13px;
//             line-height: 8vw;
//         }

//         .content {
//             color: #40a9ff;
//             font-size: 15px;
//         }

//         .wa {
//             font-size: 12px;
//             color: #6d6f73;
//             font-weight: 100;
//             display: flex;
//             align-items: center;
//             justify-content: center;

//             img {
//                 width: 5vw;
//                 margin-right: 1vw;
//             }
//         }

//         .email {
//             font-size: 12px;
//             color: #6d6f73;
//             font-weight: 100;
//             display: flex;
//             align-items: center;
//             justify-content: center;

//             img {
//                 width: 5vw;
//                 margin-right: 1vw;
//             }
//         }

//         p {
//             color: #6d6f73;
//             font-size: 12px;
//             font-weight: 100;
//             text-align: left;
//             display: block;
//             width: 90vw;
//             margin: auto;
//             line-height: 5vw;
//             padding-top: 2vw;
//         }
//     }

//     .show2 {
//         background: none;

//         .van-popup__close-icon {
//             color: #fff;
//             font-size: 7vw;
//             margin-top: 4vw;
//         }
//     }

//     .currency-list {
//         padding: 20px;
//         width: 80%;

//         .van-icon {
//             position: absolute;
//             top: 0;
//             right: 0;
//         }

//         .title {
//             font-weight: bold;
//             font-size: 20px;
//             text-align: center;
//             margin: 8px 0;
//         }

//         ul {
//             display: flex;
//             justify-content: space-between;
//             flex-wrap: wrap;

//             li {
//                 width: 126px;
//                 height: 48px;
//                 background: #f5f5f5;
//                 border-radius: 24px;
//                 text-align: center;
//                 line-height: 48px;
//                 margin: 8px 0;
//             }
//         }
//     }

//     .liaojieImg {
//         img {
//             width: 70vw;
//         }
//     }

//     /*.van-popup {*/
//     /*    background: none;*/
//     /*}*/

//     // .payPage {
//     //   border-radius: 4vw;
//     //   height: 60vw;
//     //   background: #fff;

//     //   iframe {
//     //     margin: auto;
//     //     padding-top: 5vw;
//     //   }
//     // }
//     .payPage {
//         width: 76vw;
//         padding: 5vw 4vw;
//         background: #fff;
//         border-radius: 4vw;

//         .content {
//             .payText {
//                 span {
//                     display: block;
//                 }

//                 .title {
//                     color: #333333;
//                     font-size: 18px;
//                     font-weight: 600;
//                 }

//                 .payTip {
//                     color: #6d6f73;
//                     font-size: 15px;
//                     font-weight: 500;
//                     margin-top: 2vw;
//                 }
//             }

//             .checkCard {
//                 margin-top: 5vw;

//                 .checkText {
//                     color: #6d6f73;
//                     font-size: 13px;
//                     font-weight: 500;
//                 }

//                 .img-icon {
//                     width: 5.5vw;
//                     height: 5.5vw;
//                 }
//             }
//         }

//         .buttom {
//             margin-top: 6vw;
//             span {
//                 display: inline-block;
//                 width: 24vw;
//                 font-size: 16px;
//                 padding: 3vw 5vw;
//                 border-radius: 10vw;
//                 margin: 0 1vw;
//             }

//             .cancel {
//                 border: 1px solid #cccccc;
//                 background: #fff;
//                 color: #999999;
//             }

//             .confirm {
//                 background-image: linear-gradient(to right, #ff9b67, #fb6c90);
//                 color: #fff;
//             }
//         }
//     }
// }
</style>
  