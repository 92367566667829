<template>
  <div class="nobleFrame">
    <div class="topTitle">
      <van-icon name="arrow-left" @click="toHome()" /><span>Aristocracy</span>
    </div>
    <div class="contentTab">
      <van-tabs v-model="active" title-active-color="#e7b772" swipeable>
        <van-tab title="Baron">
          <nobleLv1></nobleLv1>
        </van-tab>
        <van-tab title="Viscount">
          <nobleLv2></nobleLv2>
        </van-tab>
        <van-tab title="Marquis">
          <nobleLv3></nobleLv3>
        </van-tab>
        <van-tab title="Duke">
          <nobleLv4></nobleLv4>
        </van-tab>
        <van-tab :title="tab5Title">
          <nobleLv5 :title="tab5Title" @changeTabtitle="getTabtitle"></nobleLv5>
        </van-tab>
        <van-tab title="Emperor">
          <nobleLv6></nobleLv6>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>

const nobleLv1 = () => import('@/components/noble_en/nobleLv1');
const nobleLv2 = () => import('@/components/noble_en/nobleLv2');
const nobleLv3 = () => import('@/components/noble_en/nobleLv3');
const nobleLv4 = () => import('@/components/noble_en/nobleLv4');
const nobleLv5 = () => import('@/components/noble_en/nobleLv5');
const nobleLv6 = () => import('@/components/noble_en/nobleLv6');
export default {
  name: 'noble',
  components: {
    nobleLv1: nobleLv1,
    nobleLv2: nobleLv2,
    nobleLv3: nobleLv3,
    nobleLv4: nobleLv4,
    nobleLv5: nobleLv5,
    nobleLv6: nobleLv6
  },
  data () {
    return {
      active: 0,
      tab5Title: 'King'
    }
  },
  methods: {
    toHome () {
      this.$router.push('/')
    },
    getTabtitle(val){
      this.tab5Title = val
    }
  }
}
</script>

<style lang="scss" scoped>
.nobleFrame {
  .topTitle {
    background: #292c31;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 4vw 0;
    .van-icon {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      margin: 0 30vw 0 4vw;
    }
    span {
    }
  }
  .contentTab {
    .van-tabs__nav {
      background: #292c31;
    }
    .van-tab {
      font-weight: bold;
      color: #ffffff4d;
    }
    .van-tab--active {
      color: #e7b772;
      font-weight: bold;
    }
    .van-tabs__line {
      background: #e7b772;
      width: 13px;
      bottom: 21px;
    }
  }
}
</style>