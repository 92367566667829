<template>
    <div id="fail" class="fail" :dir="dir">
        <img src="../assets/imgs/fail.png" alt="">
        <!-- <span class="title" v-if="lang !== 2">Recharge fail</span>
        <span class="title" v-else>فشل في الشحن</span> -->
        <span class="title">{{ rechargeFailtext[lang] }}</span>

        <!-- <span class="btn n1" @click="tryAgain()" v-if="lang !== 2">Try again</span>
        <span class="btn n1" @click="tryAgain()" v-else>إعادة الشحن</span> -->        
        <span class="btn n1" @click="tryAgain()">{{ tryagainText[lang] }}</span>

        <!-- <span class="btn n2" @click="returnPages()" v-if="lang !== 2">OK</span>
        <span class="btn n2" @click="returnPages()" v-else>حسنا</span> -->
        <span class="btn n2" @click="returnPages()">{{ okText[lang] }}</span>

    </div>
</template>

<script>
    import {timeConvert} from "@/assets/js/utils";

    export default {
        name: "Fail",
        data(){
            return {
                rechargeFailtext: {
                    1: 'Recharge fail',
                    2: 'فشل في الشحن',
                    3: 'Şarj etmek başarısız'
                },
                tryagainText: {
                    1: 'Try again',
                    2: 'إعادة الشحن',
                    3: 'Tekrar deneyin'
                },
                okText: {
                    1: 'OK',
                    2: 'حسنا',
                    3: 'TAMAM'
                }
            }
        },
        computed: {
            pay_time() {
                let pay_time = this.$route.query.pay_time
                return pay_time ? timeConvert(pay_time): ''
            },
            lang() {
                let lang = this.$route.query.lang
                return  lang ? parseInt(lang) : 1
            },
            dir() {
                return this.lang !== 2 ? 'ltr': 'rtl'
            }
        },

        methods: {
            returnPages () {
                if (this.lang === 2) {
                    this.$router.push('/ar')
                } else if (this.lang === 3){
                    this.$router.push('/tr')
                } else {
                    this.$router.push('/')
                }
            },
            tryAgain () {
                this.$router.go(-1)
            },
        }

    }
</script>

<style scoped lang="scss">
    .fail {
        background: #fff;
        overflow: hidden;
        color: #333;
        font-size: 15px;
        img {
            width: 25vw;
            display: block;
            margin: auto;
            margin-top: 15vw;
            padding-bottom: 2vw;
        }
        ul {
            margin-top: 10vw;
            li {
                width: 100vw;
                border-bottom: 0.5px solid #dbdbdb;
                text-align: left;
                padding: 4vw 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                span {
                    display: inline-block;
                }
                .listTitle {
                    margin-left: 3vw;
                }
                .data {
                    font-size: 14px;
                    color: #6d6f73;
                    font-weight: initial;
                    margin-right: 3vw;
                }
            }
            li:nth-last-child(0) {
                border: none;
            }
        }
        .btn {
            color: #fff;
            background: #73d13d;
            font-size: 16px;
            width: 45vw;
            display: block;
            margin: auto;
            margin-top: 10vw;
            padding: 3vw 0;
            border-radius: 8vw;
        }
        .btn {
            margin-top: 5vw;
            width: 50vw;
        }
        .n1 {
            background: #ff7875;
            color: #fff;
        }
        .n2 {
            background: #fff;
            color: #ff7875;
            border: 0.5px solid #ff7875;
        }
    }
</style>