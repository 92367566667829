import { render, staticRenderFns } from "./Fawry.vue?vue&type=template&id=8bf95e1c&scoped=true"
import script from "./Fawry.vue?vue&type=script&lang=js"
export * from "./Fawry.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bf95e1c",
  null
  
)

export default component.exports